import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UnitService } from '../../services/unit.service';
import { CollapsingControls } from '../../collapsing-controls';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { KernelResultsCapacityOfRetrofittedWall } from '../../services/design.service';

@Component({
    selector: 'app-output-utilizations',
    templateUrl: './output-utilizations.component.html',
    styleUrls: ['./output-utilizations.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class OutputUtilizationsComponent implements OnChanges {

    @Input()
    public outputCapacityOfRetrofittedWall?: KernelResultsCapacityOfRetrofittedWall;
    @Input()
    public axialCompressiveLoad?: number;
    @Input()
    public inPlaneBendingMoment?: number;
    @Input()
    public outOfPlaneBendingMoment?: number;
    @Input()
    public shearLoad?: number;

    public collapsed = false;

    constructor(
        public localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private unitService: UnitService
    ) { }

    public ngOnChanges(): void {
        this.collapsed = this.userSettingsService.isSectionCollapsed(CollapsingControls.OutputUtilizationsComponent);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(CollapsingControls.OutputUtilizationsComponent, collapsed);
    }

    public get axialLoadNtcResult() {
        return (this.axialCompressiveLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumAxialLoadNtc ?? 1) * 100;
    }

    public get axialLoadResult() {
        return (this.axialCompressiveLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumAxialLoad ?? 1) * 100;
    }

    public get inPlaneBendingMomentNtcResult() {
        return (this.inPlaneBendingMoment ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMomentNtc ?? 1) * 100;
    }

    public get inPlaneBendingMomentResult() {
        return (this.inPlaneBendingMoment ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMoment ?? 1) * 100;
    }

    public get outOfPlaneBendingMomentNtcResult() {
        return (this.outOfPlaneBendingMoment ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMomentNtc ?? 1) * 100;
    }

    public get outOfPlaneBendingMomentResult() {
        return (this.outOfPlaneBendingMoment ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMoment ?? 1) * 100;
    }

    public get shearResistanceDiagonalCrackingNtcResult() {
        return (this.shearLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCrackingNtc ?? 1) * 100;
    }

    public get shearResistanceDiagonalCrackingResult() {
        return (this.shearLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCracking ?? 1) * 100;
    }

    public get shearResistanceStairSteppedNtcResult() {
        return (this.shearLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairSteppedNtc ?? 1) * 100;
    }

    public get shearResistanceStairSteppedResult() {
        return (this.shearLoad ?? 0) / (this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairStepped ?? 1) * 100;
    }

    public isVerified(value: number | undefined): boolean {
        return (value ?? 0) <= 100;
    }
    public format(internalUnit: number | undefined, precision?: number): string {
        if (internalUnit == null) {
            return 'INF';
        }
        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Percentage);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Percentage);
        return this.unitService.formatUnitValueArgs(value, unitGroup, precision, undefined, undefined, undefined, true);
    }
}
