import { TranslationFormat } from './services/design.service';
import { TranslationFormatService } from './services/translation-format.service';

export function CreateMessageFromKeys(keys: TranslationFormat[], translationFormatService: TranslationFormatService) : string {
    let message = '';

    for (let i = 0; i < keys.length; i++) {
        message += translationFormatService.getLocalizedStringWithTranslationFormat(keys[i]);
        if (i < keys.length - 1) {
            message += ' \n\r';
        }
    }

    return message;
}