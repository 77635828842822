import { CodeList, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { IButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IImageNameRadioGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IImageNameSelectionGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, Button, DropDown, ImageNameSelectionGroup, Menu, TextBox, ToggleButtonGroup, UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, TabItem } from '@profis-engineering/pe-ui-common/services/menu.common';
import { getSpriteAsIconStyle } from '../sprites';
import { PropertyIdValue } from './design.service';
import { FavoritesService } from './favorites.service';
import { LocalizationService } from './localization.service';
import { CodeLists, CodeListType, SetMenuOptions } from './menu.service';
import { NumberService } from './number.service';
import { StaticMenuService } from './static-menu.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

export class MenuServiceExtensions implements IMenuServiceExtensions {
    private propertyChange!: (propertyChanges: PropertyIdValue[]) => Promise<void>;

    private staticMenuService: StaticMenuService;
    private localizationService: LocalizationService;
    private unitService: UnitService;
    private numberService: NumberService;
    private codeLists: CodeLists;
    private userService: UserService;
    private favoritesService: FavoritesService;
    constructor(
        options: SetMenuOptions,
        staticMenuService: StaticMenuService,
        localizationService: LocalizationService,
        unitService: UnitService,
        numberService: NumberService,
        userService: UserService,
        favoritesService: FavoritesService,
        codeLists: CodeLists
    ) {
        this.propertyChange = options.propertyChange;
        this.staticMenuService = staticMenuService;
        this.localizationService = localizationService;
        this.unitService = unitService;
        this.numberService = numberService;
        this.userService = userService;
        this.codeLists = codeLists;
        this.favoritesService = favoritesService;
    }
    // Data
    public getMenuStructure(): Menu<BaseControl<string>, string> | undefined {
        return this.staticMenuService.getMenu() as Menu<BaseControl<string>, string>;
    }

    public getMenuCommands(): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {};
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {};
    }

    // Common
    public async calculateAsync(design: Design, changeFn?: ((design: Design) => void) | undefined): Promise<ICalculationResult> {
        changeFn?.(design);

        // get changes
        design.modelChanges.observe();
        const changes = design.modelChanges.changes;
        design.modelChanges.clear();

        // call update if we have changes
        if (changes.length > 0) {
            const propertyChanges = changes.map((x): PropertyIdValue => ({
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                propertyId: x.name as any,
                propertyValue: x.newValue
            }));
            await this.propertyChange(propertyChanges);
        }

        return undefined as unknown as ICalculationResult;
    }

    public trackUsage() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getDesignCodeList(codelistName: string): number {
        // TODO FILIP: why does menu need a number id for a codelist?
        return 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getProjectCodeList(codelistName: string): number {
        // TODO FILIP: why does menu need a number id for a codelist?
        return 0;
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: Design, codeList: number): CodeList[] | undefined {

        if (codeList != null) {
            return this.codeLists[codeList as unknown as CodeListType];
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    public getCodeListIdWithItems(design: Design, navigationControl: UIPropertyBaseControl<string>): IMenuServiceCodeListIdWithItems | undefined {
        return {
            codeList: navigationControl.CodelistName as unknown as number,
            codeListItems: this.codeLists[navigationControl.CodelistName as CodeListType]
        };
    }

    public getCodeListItemText(design: Design, codeList: unknown, codeListItem: CodeList, codeListDeps: ICodeListTextDeps): string | undefined {
        const codeListType = codeList as CodeListType;
        if (codeListType == 'plasterThickness') {
            const selectedUnit = this.designDetails.properties.unitLength;
            const unit = this.codeLists.unitLength.find(x => x.id == selectedUnit);
            const unitStr = unit ? ' ' + unit.name : '';
            return this.unitService.convertUnitValueArgsToUnit(Number(codeListItem.name), UnitType.mm, selectedUnit, true).toString() + unitStr;
        }
        if (codeListType == 'numberOfAnchors') {
            const selectedUnit = this.designDetails.properties.unitItemsPerArea;
            const unit = this.codeLists.unitItemsPerArea.find(x => x.id == selectedUnit);
            const unitStr = unit ? ' ' + unit.name : '';
            return this.unitService.convertUnitValueArgsToUnit(Number(codeListItem.name), UnitType.item_m2, selectedUnit, true).toString() + unitStr;
        }
        return codeListItem.getTranslatedNameText(codeListDeps);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getDesignStandard(design: Design): CodeList | undefined {
        // TODO FILIP: why does the menu need design standard?
        return undefined;
    }


    public getTabControlId(name: string): string | undefined {
        return name;
    }

    public updateTabs() {
        /* Nothing to do. */
    }

    public isFavoritesTabHidden() {
        return false;
    }

    isTabDisabled(): boolean {
        return false;
    }

    public getRegionDisplayId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdFavorites(id, this.designDetails.designTypeId);
    }

    public setRegionKbFields() {
        /* Nothing to do. */
    }


    public clientHidden() {
        return false;
    }


    public formatDisplayStringModule(textKey: string, design: Design, codeList?: number | undefined, uiPropertyId?: number | undefined): string | undefined {
        if (codeList) {
            return this.formatStringByCodeList(textKey, design, codeList);
        }

        if (uiPropertyId) {
            return this.formatStringByUiPropertyId(textKey, design, uiPropertyId);
        }

        let text = this.localizationService.getString(textKey);

        if (text.includes('{minPlasterCompressiveStrength}') && '{maxPlasterCompressiveStrength}') {
            const minValue = this.unitService.formatInternalValueAsDefault(15, UnitGroup.Stress);
            const maxValue = this.unitService.formatInternalValueAsDefault(35, UnitGroup.Stress);
            text = text.replace('{minPlasterCompressiveStrength}', minValue).replace('{maxPlasterCompressiveStrength}', maxValue);
        }
        else if (text.includes('{minCompressiveStrength}') && '{maxCompressiveStrength}') {
            const minValue = this.unitService.formatInternalValueAsDefault(2.6, UnitGroup.Stress, 2);
            const maxValue = this.unitService.formatInternalValueAsDefault(4.3, UnitGroup.Stress, 2);
            text = text.replace('{minCompressiveStrength}', minValue).replace('{maxCompressiveStrength}', maxValue);
        }
        else if (text.includes('{minShearStrengthDiagonalCrack}') && '{maxShearStrengthDiagonalCrack}') {
            const minValue = this.unitService.formatInternalValueAsDefault(0.05, UnitGroup.Stress, 2);
            const maxValue = this.unitService.formatInternalValueAsDefault(0.13, UnitGroup.Stress, 2);
            text = text.replace('{minShearStrengthDiagonalCrack}', minValue).replace('{maxShearStrengthDiagonalCrack}', maxValue);
        }
        else if (text.includes('{minShearStrengthStairSteppedCrack}') && '{maxShearStrengthStairSteppedCrack}') {
            const minValue = this.unitService.formatInternalValueAsDefault(0.13, UnitGroup.Stress, 2);
            const maxValue = this.unitService.formatInternalValueAsDefault(0.27, UnitGroup.Stress, 2);
            text = text.replace('{minShearStrengthStairSteppedCrack}', minValue).replace('{maxShearStrengthStairSteppedCrack}', maxValue);
        }

        return text;
    }

    public getLocalizedStringWithTranslationFormat() {
        return '';
    }

    // Controls
    public createMainMenuControlModule() {
        return undefined;
    }

    // TextBox
    public overrideTextBoxProps() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public overrideTextBoxUnitGroup(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox<string>, setState?: (() => void) | undefined): void {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        /* Nothing to do. */
    }

    // Dropdown
    public overrideDropdownProps() {
        /* Nothing to do. */
    }

    public getDropdownItemProps(): IMenuServiceDropdownItemProps | undefined {
        return {};
    }

    public setDropdownOnAllowedValuesChanged() {
        /* Nothing to do. */
    }

    public setDropdownOnStateChanged(
        design: Design,
        designCodeList: number,
        state: IDesignStateBase,
        _menu: IMenu,
        navigationControl: DropDown<string>,
        _controlProps: IDropdownProps,
        onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps
    ): IDropdownProps | undefined {
        return onStateChangeFn((codeListItem) => this.getCodeListItemText(design, designCodeList, codeListItem, { localizationService: this.localizationService, numberService: this.numberService })!, undefined);
    }

    // Checkbox
    public overrideCheckboxProps(_design: Design, controlProps: ICheckboxProps) {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = getSpriteAsIconStyle(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = getSpriteAsIconStyle(`${controlProps.iconImage}-selected`);
        }
    }

    // CheckboxGroup
    public overrideCheckboxGroupProps() {
        /* Nothing to do. */
    }

    // RadioButton
    public overrideRadioButtonProps() {
        /* Nothing to do. */
    }

    // RadioButtonGroup
    public overrideRadioButtonGroupProps() {
        /* Nothing to do. */
    }

    public getApplySortOrderItemIds(): number[] | undefined {
        return undefined;
    }

    public getRadioGroupParentId(): number | undefined {
        return undefined;
    }

    // Button
    public overrideButtonProps(_design: Design, controlProps: IButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }
    }

    // ButtonGroup
    public overrideButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateButtonGroupItemProps(_design: Design, _navigationControl: Button, item: IButtonGroupItem) {
        if (item.image != null && item.image != '') {
            item.imageStyle = getSpriteAsIconStyle(item.image);
        }
    }

    // ToggleButton
    public overrideToggleButtonProps(_design: Design, controlProps: IToggleButtonProps) {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    // ToggleImageButton
    public overrideToggleImageButtonProps(_design: Design, controlProps: IToggleImageButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = getSpriteAsIconStyle(controlProps.alternateImage);
        }
    }

    // ToggleButtonGroup
    public overrideToggleButtonGroupProps() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateToggleButtonGroupItemCodeListProps(design: Design, navigationControl: ToggleButtonGroup<string>, codeListItem: CodeList): void {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemProps(__design: Design, _navigationControl: ToggleButtonGroup, item: IToggleButtonGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public getToggleButtonGroupAllowedValues(_codeList: number, allowedValues: number[]): number[] {
        return allowedValues;
    }

    // Group
    public overrideGroupProps() {
        /* Nothing to do. */
    }

    // Label
    public overrideLabelProps() {
        /* Nothing to do. */
    }

    // Rotate
    public overrideRotateProps() {
        /* Nothing to do. */
    }

    // PopupGrid
    public overridePopupGridProps() {
        /* Nothing to do. */
    }

    public customizePopupGridModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions): void {
        modalOpts.size = 'lg';
    }

    public customizePopupGridItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public getPopupGridHideShowDescriptionOnButton() {
        return false;
    }

    // PopupGridPartial
    public overridePopupGridPartialProps() {
        /* Nothing to do. */
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps) {
        controlProps.numberOfButtons = 3;
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions) {
        modalOpts.size = 'lg';
    }

    // Integrations
    public overrideDlubalImportExportProps() {
        /* Nothing to do. */
    }

    public overrideSAP2000ImportExportProps() {
        /* Nothing to do. */
    }

    public overrideRobotImportExportProps() {
        /* Nothing to do. */
    }

    public overrideETABSImportExportProps() {
        /* Nothing to do. */
    }

    public overrideStaadProImportExportProps() {
        /* Nothing to do. */
    }

    // ImageNameRadioGroup
    public overrideImageNameRadioGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameRadioGroupItemProps(_design: Design, _navigationControl: UIPropertyBaseControl, item: IImageNameRadioGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public updateImageNameRadioGroupSortOrder(): Promise<void> {
        return Promise.resolve();
    }

    // ImageNameSelectionGroup
    public overrideImageNameSelectionGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameSelectionGroupItemProps(_design: Design, _navigationControl: ImageNameSelectionGroup, item: IImageNameSelectionGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    // SwitchWithDescription
    public overrideSwitchWithDescriptionProps() {
        /* Nothing to do. */
    }

    // RangeSlider
    public overrideRangeSliderProps() {
        /* Nothing to do. */
    }

    // TabGroup
    public overrideTabGroupProps() {
        /* Nothing to do. */
    }

    public overrideTabGroupItems() {
        /* Nothing to do. */
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem): UIPropertyBaseControl<string>[] {
        return childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
    }

    public setFooterControlVisibility(design: Design, menu: IMenu): IMenu | undefined {
        return menu;
    }

    private get designDetails() {
        return this.userService.design.designDetails;
    }

    private getIconStyleForImage(image: string | undefined): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return getSpriteAsIconStyle(image);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private formatStringByCodeList(textKey: string, design: Design, codeList: number) {
        return this.localizationService.getString(textKey);
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            console.warn('Missing code list: %s', navigationControl.CodelistName);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private formatStringByUiPropertyId(textKey: string, design: Design, uiPropertyId: number) {
        return this.localizationService.getString(textKey);
    }
}