import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

// import sprites info and images
import mainSprite from './bundles/main.json';
import mainImage from './bundles/main.png';

// sprite types
export type MainSprite = keyof typeof mainSprite;

// add more sprites with |
export type Sprite =
    MainSprite;

export type SpriteClass = `pe-ui-masonry-rnf-${Sprite}`;

// add more sprites with ...
const spritesInfo = {
    ...toSpriteInfo(mainSprite, 'main.png')
};

const imagesUrl: Record<string, string> = {
    'main.png': mainImage
};

interface JsonSpriteDetails {
    x: number;
    y: number;
    w: number;
    h: number;
    tw: number;
    th: number;
}

interface SpriteDetails extends JsonSpriteDetails {
    image: string;
}

type SpriteInfo = Record<string, SpriteDetails>;

function toSpriteInfo(sprites: Record<string, JsonSpriteDetails>, image: string): SpriteInfo {
    return Object.fromEntries((Object.entries(sprites)).map(([spriteName, sprite]) => {
        return [spriteName, {
            ...sprite,
            image
        }];
    }));
}

export function includeSprites(root: Node | null, ...sprites: Sprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css = `
            .pe-ui-masonry-rnf-sprite {
                display: block;
            }
        `;

        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];
            if (spriteInfo == null) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing json)`);
                continue;
            }

            const imageUrl = imagesUrl[spriteInfo.image];

            if (!imageUrl) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing image)`);
                continue;
            }

            css += `
                .pe-ui-masonry-rnf-sprite.pe-ui-masonry-rnf-${sprite} {
                    background-image: url(${imageUrl});
                    background-position: -${spriteInfo.x}px -${spriteInfo.y}px;
                    width: ${spriteInfo.w}px;
                    min-width: ${spriteInfo.w}px;
                    height: ${spriteInfo.h}px;
                }
            `;

            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}

export function getSpriteAsIconStyle(image: Sprite): IIconStyle;
export function getSpriteAsIconStyle(image: string): IIconStyle;
export function getSpriteAsIconStyle(image: Sprite | string): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    if (spriteInfo != undefined) {
        const imageUrl = imagesUrl[spriteInfo.image];

        return {
            'background-image': `url(${imageUrl})`,
            'background-position': `-${spriteInfo.x}px -${spriteInfo.y}px`,
            'width': `${spriteInfo.w}px`,
            'min-width': `${spriteInfo.w}px`,
            'height': `${spriteInfo.h}px`,
        };
    }
    return {};
}

export function getSpriteAsIconStyleResponsive(image: Sprite): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: string): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: Sprite | string): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    if (spriteInfo != undefined) {
        const imageUrl = imagesUrl[spriteInfo.image];

        const x = ((spriteInfo.x * 100) / (spriteInfo.tw - spriteInfo.w));
        const y = ((spriteInfo.y * 100) / (spriteInfo.th - spriteInfo.h));
        const bgWidth = spriteInfo.tw * 100 / spriteInfo.w;
        const paddingBottom = spriteInfo.h * 100 / spriteInfo.w;
        const width = spriteInfo.tw * 100 / spriteInfo.w;

        return {
            'background-image': `url(${imageUrl})`,
            'background-position': `${x}% ${y}%`,
            'background-size': `${bgWidth}% auto`,
            'width': `${width}%`,
            'max-width': '100%',
            'height': '0',
            'padding-bottom': `${paddingBottom}%`,
            'display': 'block'
        };
    }
    return {};
}

function getSpriteInfo(image: Sprite | string): SpriteDetails | undefined {
    if (!(image in spritesInfo)) {
        console.warn(`Sprite with name '${image}' does not exist (missing json)`);
        return undefined;
    }

    const spriteInfo = spritesInfo[image as Sprite];

    const imageUrl = imagesUrl[spriteInfo.image];
    if (!imageUrl) {
        console.warn(`Sprite with name '${image}' does not exist (missing image)`);
        return undefined;
    }

    return spriteInfo;
}
