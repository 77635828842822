import { Injectable } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitPrecision, UnitServiceBase, UnitServiceInjected } from '@profis-engineering/pe-ui-common/services/unit.common';
import { designTypes, PropertyId } from './design.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceInjected {
    constructor(
        private userService: UserService
    ) {
        super();
    }

    public override setBaseService(baseService: UnitServiceBase): void {
        const userService = this.userService;

        // pe-ui is still not modularized
        // here we are changing the base service that is used by everyone so we have to be careful
        const baseGetPrecision = baseService.getPrecision;
        baseService.getPrecision = function(unit: UnitType, uiProperty?: string, unitPrecision?: UnitPrecision) {
            // only for our designs
            if (userService.design?.designTypeId == designTypes.main.id) {
                const customPrecision = userService.design.designDetails?.propertyDetails[uiProperty as PropertyId]?.precision;
                if (customPrecision != null) {
                    return customPrecision;
                }
            }

            return baseGetPrecision.apply(this, [unit, uiProperty, unitPrecision]);
        };

        super.setBaseService(baseService);
    }
}
