<div class="box-section box-section-white">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static">
            <span class="bar-title" [innerHtml]="'MasonryRnf.BillOfMaterial' | l10n: localizationService.selectedLanguage"></span>
        </div>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content">

        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.NumberOfAnchors"></div>
            <div class="result-value">{{ numberOfAnchorsDisplay }}</div>
        </div>

        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.TotalLengthOfRebars"></div>
            <div class="result-value">{{ totalLengthOfRebarsDisplay }}&nbsp;<span class="result-unit">{{ unitLengthDisplay }}</span></div>
        </div>

        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.MortarVolume"></div>
            <div class="result-value">{{ mortarVolumeDisplay }}&nbsp;<span class="result-unit">ml</span></div>
        </div>

        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.NumberOfCartridges"></div>
            <div class="result-value">{{ numberOfCartridgesDisplay }}</div>
        </div>

        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.SteelMeshArea"></div>
            <div class="result-value">{{ steelMeshAreaDisplay }}&nbsp;<span class="result-unit">{{ unitAreaDisplay }}</span></div>
        </div>
        <div class="bill-of-materials">
            <div class="result-label" l10n="MasonryRnf.BillOfMaterial.PlasterVolume"></div>
            <div class="result-value">{{ plasterVolumeDisplay }} &nbsp;<span class="result-unit">{{ unitVolumeDisplay }}</span></div>
        </div>
    </div>
</div>
