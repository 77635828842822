import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { KernelResultBillOfMaterial } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UnitService } from '../../services/unit.service';
import { CollapsingControls } from '../../collapsing-controls';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Component({
    selector: 'app-bill-of-materials',
    templateUrl: './bill-of-materials.component.html',
    styleUrls: ['./bill-of-materials.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BillOfMaterialsComponent implements OnChanges {

    @Input()
    public billOfMaterial?: KernelResultBillOfMaterial;

    public collapsed = false;

    constructor(
        public localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private unitService: UnitService
    ) { }

    public ngOnChanges(): void {
        this.collapsed = this.userSettingsService.isSectionCollapsed(CollapsingControls.BillOfMaterial);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(CollapsingControls.BillOfMaterial, collapsed);
    }

    // display unit might change so it's easier to have a getter instead of using events to know when to update the value
    public get unitAreaDisplay(): string {
        return this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Area));
    }

    public get unitLengthDisplay(): string {
        return this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Length));
    }
    public get unitVolumeDisplay(): string {
        return this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Volume));
    }

    public get numberOfAnchorsDisplay() {
        return this.formatNumber(this.billOfMaterial?.numberOfAnchors);
    }

    public get totalLengthOfRebarsDisplay() {
        return this.formatLength(this.billOfMaterial?.totalLengthOfRebars);
    }

    public get mortarVolumeDisplay() {
        return this.formatNumber(this.billOfMaterial?.mortarVolume);
    }

    public get numberOfCartridgesDisplay() {
        return this.formatNumber(this.billOfMaterial?.numberOfCartridges);
    }
    public get steelMeshAreaDisplay() {
        return this.formatArea(this.billOfMaterial?.steelMeshArea);
    }

    public get plasterVolumeDisplay() {
        return this.formatVolume(this.billOfMaterial?.plasterVolume);
    }

    private formatVolume(internalUnit: number | undefined): string {
        if (internalUnit == null) {
            return 'NaN';
        }
        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Volume);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Volume);
        return this.unitService.formatUnitValueArgs(value, unitGroup, undefined, undefined, undefined, undefined, false);
    }

    private formatArea(internalUnit: number | undefined): string {
        if (internalUnit == null) {
            return 'NaN';
        }
        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Area);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Area);
        return this.unitService.formatUnitValueArgs(value, unitGroup, undefined, undefined, undefined, undefined, false);
    }

    private formatLength(internalUnit: number | undefined): string {
        if (internalUnit == null) {
            return 'NaN';
        }
        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Length);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Length);
        return this.unitService.formatUnitValueArgs(value, unitGroup, undefined, undefined, undefined, undefined, false);
    }

    private formatNumber(value: number | undefined): string {
        if (value == null) {
            return 'NaN';
        }
        return this.unitService.formatUnitValueArgs(value, UnitType.None);
    }
}
