import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AppData } from './data.service';
import { ApiDesignCalculationOptions, ApiDesignCreateRequest, ApiDesignReportGenerateOptions, ApiDesignUpdateRequest, ApiDesignUpdateResponse, ApiHtmlReportGenerateOptions, CalculationResult, CalculationStatus, ConfirmationType, ConvertAndCalculateResult, CreateAndCalculateResult, CustomPictureData, DesignDetailsData, DesignServiceUpdateDesignOptions, DesignUpdateRequest, HtmlReportPaperSize, ImageUploadResponse, KernelResultBillOfMaterial, KernelResultCapacityOfUnreinforcedWall, KernelResultMechanicalParametersRetrofittedWall, KernelResultsCapacityOfRetrofittedWall, KernelResultsIncrementOfCapacityOfRetrofittedWall, ProjectDesign, PropertyId, ReportPaperSizeId, RequiresConfirmation, UpdateResult, UploadCustomPicture } from './design.service';
import { DotnetService } from './dotnet.service';
import { ModalService } from './modal.service';
import { TranslationFormatService } from './translation-format.service';
import { CreateMessageFromKeys } from '../modal-helper';

export const enum CoreApiType {
    web,
    webassembly
}

@Injectable({
    providedIn: 'root'
})
export class CoreApiService {
    constructor(
        private dotnetService: DotnetService,
        private apiService: ApiService,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService
    ) { }

    public type = CoreApiType.web;

    public get isLongRunning() {
        return this.type == CoreApiType.web;
    }

    public api = {
        app: {
            data: async (apiOptions?: ApiOptions): Promise<AppData> => {
                if (this.type == CoreApiType.web) {
                    const url = `${environment.designServiceUrl}app/data`;
                    const response = await this.apiService.request<AppData>(new HttpRequest('GET', url), {
                        supressErrorMessage: true,
                        ...apiOptions
                    });

                    return response.body as AppData;
                }

                return await this.dotnetService.api.app.data();
            }
        },
        design: {
            convert: async (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ProjectDesign> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiDesignConvert(projectDesign, apiOptions);
                }

                return await this.dotnetService.api.design.convert(projectDesign);
            },
            create: async (designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<ProjectDesign> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiDesignCreate(designCreateRequest, apiOptions);
                }

                return await this.dotnetService.api.design.create(designCreateRequest);
            },
            details: async (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<DesignDetailsData> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiDesignDetails(projectDesign, apiOptions);
                }

                return await this.dotnetService.api.design.details(projectDesign);
            },
            update: async (updateDesignOptions: DesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<ApiDesignUpdateResponse> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiDesignUpdate(updateDesignOptions, apiOptions);
                }

                return await this.dotnetService.api.design.update(updateDesignOptions);
            },
            uploadImage: async (uploadCustomPicture: UploadCustomPicture, apiOptions?: ApiOptions): Promise<ImageUploadResponse[]> => {
                return await this.webApiDesignCustomPictureUpload(uploadCustomPicture, apiOptions);
            },
            getImage: async (customPictureData: CustomPictureData, apiOptions?: ApiOptions): Promise<string[]> => {
                return await this.webApiDesignGetCustomPicture(customPictureData, apiOptions);
            },
        },
        calculation: {
            calculate: async (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CalculationResult | undefined> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiCalculationCalculate(projectDesign, apiOptions);
                }

                return await this.dotnetService.api.calculation.calculate(projectDesign);
            }
        },
        report: {
            generate: async (designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiReportGenerate(designReportGenerateOptions, apiOptions);
                }

                const htmlReport = await this.dotnetService.api.report.generateHtml(designReportGenerateOptions);
                const htmlReportInput: ApiHtmlReportGenerateOptions = {
                    html: htmlReport,
                    reportPaperSize: mapReportPaperSize(designReportGenerateOptions.reportPaperSizeId)
                };

                return await this.webApiHtmlReportGenerate(htmlReportInput, apiOptions);

                function mapReportPaperSize(reportPaperSizeId: ReportPaperSizeId): HtmlReportPaperSize {
                    switch (reportPaperSizeId) {
                        case ReportPaperSizeId.A4:
                            return 'a4';
                        case ReportPaperSizeId.Letter:
                            return 'letter';
                        default:
                            throw new Error('Unknown ReportPaperSizeId');
                    }
                }
            },
            generateHtml: async (designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<string> => {
                if (this.type == CoreApiType.web) {
                    return await this.webApiReportGenerateHtml(designReportGenerateOptions, apiOptions);
                }

                return await this.dotnetService.api.report.generateHtml(designReportGenerateOptions);
            }
        },
        core: {
            createAndCalculate: async (designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> => {
                if (this.type == CoreApiType.web) {
                    const projectDesign = await this.webApiDesignCreate(designCreateRequest, apiOptions);

                    const designDetailsPromise = this.webApiDesignDetails(projectDesign, apiOptions);
                    const calculationResultPromise = this.webApiCalculationCalculate(projectDesign);

                    const designDetails = await designDetailsPromise;
                    const calculationResult = await calculationResultPromise;

                    this.setKernelResults(designDetails, calculationResult);

                    return {
                        projectDesign,
                        designDetails,
                        calculationResult
                    };
                }

                const result = await this.dotnetService.api.core.createAndCalculate(designCreateRequest);
                this.setKernelResults(result.designDetails, result.calculationResult);

                return result;
            },
            convertAndCalculate: async (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ConvertAndCalculateResult> => {
                if (this.type == CoreApiType.web) {
                    projectDesign = await this.webApiDesignConvert(projectDesign, apiOptions);

                    const designDetailsPromise = this.webApiDesignDetails(projectDesign, apiOptions);
                    const calculationResultPromise = this.webApiCalculationCalculate(projectDesign);

                    const designDetails = await designDetailsPromise;
                    const calculationResult = await calculationResultPromise;

                    this.setKernelResults(designDetails, calculationResult);

                    return {
                        projectDesign,
                        designDetails,
                        calculationResult
                    };
                }
                const result = await this.dotnetService.api.core.convertAndCalculate(projectDesign);
                this.setKernelResults(result.designDetails, result.calculationResult);

                return result;
            },
            updateAndCalculate: async (designUpdateRequest: DesignUpdateRequest, apiOptions?: ApiOptions): Promise<UpdateResult> => {
                if (this.type == CoreApiType.web) {
                    let designUpdateResponse = await this.webApiDesignUpdate({
                        ...designUpdateRequest,
                        confirmed: false
                    }, apiOptions);

                    if (designUpdateResponse.requiresConfirmation) {
                        if (!await this.getConfirmation(designUpdateResponse.requiresConfirmation)) {
                            return {
                                resetAction: true
                            };
                        }

                        designUpdateResponse = await this.webApiDesignUpdate({
                            ...designUpdateRequest,
                            confirmed: true
                        }, apiOptions);
                    }

                    return this.createCalculationResult(designUpdateResponse.projectDesign!, apiOptions).then((result) => {
                        return {
                            designCalculationResult: result
                        };
                    });
                }

                // WASM
                let designUpdateResponse = await this.dotnetService.api.core.updateAndCalculate({
                    ...designUpdateRequest,
                    confirmed: false
                });

                if (designUpdateResponse.requiresConfirmation) {
                    if (!await this.getConfirmation(designUpdateResponse.requiresConfirmation)) {
                        return {
                            resetAction: true
                        };
                    }

                    designUpdateResponse = await this.dotnetService.api.core.updateAndCalculate({
                        ...designUpdateRequest,
                        confirmed: true
                    });
                }
                // TODO move to updateAndCalculate
                this.setKernelResults(designUpdateResponse.designDetails, designUpdateResponse.calculationResult);
                return {
                    designCalculationResult: designUpdateResponse
                };
            }
        }
    };

    private async createCalculationResult(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> {
        const designDetailsPromise = this.webApiDesignDetails(projectDesign, apiOptions);
        const calculationResultPromise = this.webApiCalculationCalculate(projectDesign);

        const designDetails = await designDetailsPromise;
        const calculationResult = await calculationResultPromise;

        this.setKernelResults(designDetails, calculationResult);

        return {
            projectDesign,
            designDetails,
            calculationResult
        };
    }

    private async webApiDesignCreate(designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<ProjectDesign> {
        const url = `${environment.designServiceUrl}design/create`;
        return (await this.apiService.request<ProjectDesign>(new HttpRequest('POST', url, designCreateRequest), apiOptions)).body!;
    }

    private async webApiDesignUpdate(designUpdateRequest: ApiDesignUpdateRequest, apiOptions?: ApiOptions): Promise<ApiDesignUpdateResponse> {
        const url = `${environment.designServiceUrl}design/update`;
        return (await this.apiService.request<ApiDesignUpdateResponse>(new HttpRequest('POST', url, designUpdateRequest), apiOptions)).body!;
    }

    private async webApiDesignCustomPictureUpload(uploadCustomPicture: UploadCustomPicture, apiOptions?: ApiOptions): Promise<ImageUploadResponse[]> {
        const url = `${environment.designServiceUrl}design/uploadImage`;
        return (await this.apiService.request<ImageUploadResponse[]>(new HttpRequest('POST', url, uploadCustomPicture), apiOptions)).body!;
    }

    private async webApiDesignGetCustomPicture(customPictureData: CustomPictureData, apiOptions?: ApiOptions): Promise<string[]> {
        const url = `${environment.designServiceUrl}design/getImage`;
        return (await this.apiService.request<string[]>(new HttpRequest('POST', url, customPictureData), apiOptions)).body!;
    }

    private async webApiDesignConvert(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ProjectDesign> {
        const url = `${environment.designServiceUrl}design/convert`;
        return (await this.apiService.request<ProjectDesign>(new HttpRequest('POST', url, projectDesign), apiOptions)).body!;
    }

    private async webApiDesignDetails(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<DesignDetailsData> {
        const url = `${environment.designServiceUrl}design/details`;
        return (await this.apiService.request<DesignDetailsData>(new HttpRequest('POST', url, projectDesign), apiOptions)).body!;
    }

    private async webApiReportGenerate(designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> {
        const url = `${environment.reportServiceUrl}report/generate`;
        return (await this.apiService.request<Blob>(new HttpRequest('POST', url, designReportGenerateOptions, {
            responseType: 'blob'
        }), apiOptions)).body!;
    }

    private async webApiReportGenerateHtml(designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<string> {
        const url = `${environment.reportServiceUrl}report/generate-html`;
        return (await this.apiService.request<string>(new HttpRequest('POST', url, designReportGenerateOptions, {
            responseType: 'text'
        }), apiOptions)).body!;
    }

    private async webApiHtmlReportGenerate(htmlReportGenerateOptions: ApiHtmlReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> {
        const url = `${environment.htmlReportServiceUrl}report/generate`;

        return (await this.apiService.request<Blob>(new HttpRequest('POST', url, htmlReportGenerateOptions, {
            responseType: 'blob'
        }), apiOptions)).body!;
    }

    private async webApiCalculationCalculate(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CalculationResult | undefined> {
        apiOptions = {
            supressErrorMessage: true,
            ...apiOptions
        };

        const designCalculationOptions: ApiDesignCalculationOptions = {
            projectDesign: projectDesign
        };

        try {
            const url = `${environment.calculationServiceUrl}calculation/calculate`;
            return (await this.apiService.request<CalculationResult>(new HttpRequest('POST', url, designCalculationOptions), apiOptions)).body!;
        }
        catch (error) {
            console.log(error);
            // returning undefined will show a calculation error scopecheck
            return undefined;
        }
    }

    private async getConfirmation(requiresConfirmation: RequiresConfirmation): Promise<boolean> {
        const message = CreateMessageFromKeys(requiresConfirmation.message, this.translationFormatService);
        if (requiresConfirmation.confirmationType == ConfirmationType.Information) {
            await this.modalService.showRequiresConfirmationDialog(message);
            return true;
        }
        return await this.modalService.openConfirmDialog('requires-confirmation-dialog', message);
    }

    private setKernelResults(designDetails: DesignDetailsData, calculationResult: CalculationResult | undefined) {
        for (const propertyId in designDetails.properties) {
            const property = designDetails.properties[propertyId as PropertyId];
            if (typeof property === 'object' && property !== null && property.propertyValueType === 'KernelValue') {
                let result: number | KernelResultMechanicalParametersRetrofittedWall |  KernelResultCapacityOfUnreinforcedWall | KernelResultsIncrementOfCapacityOfRetrofittedWall | KernelResultsCapacityOfRetrofittedWall | KernelResultBillOfMaterial | undefined  = undefined;
                if (calculationResult?.calculationStatus == CalculationStatus.OK && calculationResult?.kernelResult != undefined) {
                    result = calculationResult.kernelResult[property.value as keyof typeof calculationResult.kernelResult];
                }

                (designDetails.properties[propertyId as PropertyId] as number | unknown) = result;
            }
        }
    }
}
