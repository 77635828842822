import { Injectable } from '@angular/core';
import { DesignTemplateFolderBase } from '@profis-engineering/pe-ui-common/entities/template';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { DesignTemplateServiceBase, IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';

// TODO TEAM: fix common
export interface DesignTemplateServiceBaseInternal extends DesignTemplateServiceBase {
    findTemplateFolderById(templateFolderId: string): DesignTemplateFolderBase;
}

@Injectable({
    providedIn: 'root'
})
export class DesignTemplateService {
    private baseService!: DesignTemplateServiceBaseInternal;

    public setBaseService(baseService: DesignTemplateServiceBaseInternal): void {
        this.baseService = baseService;
    }

    public findById(templateId: string): DesignTemplateEntity {
        return this.baseService.findById(templateId);
    }

    public getById(templateId: string): Promise<DesignTemplateEntity> {
        return this.baseService.getById(templateId);
    }

    public create(designTemplateDocument: IDesignTemplateDocument, thumbnailId?: string): Promise<string> {
        return this.baseService.create(designTemplateDocument, thumbnailId);
    }

    public update(designTemplateDocument: IDesignTemplateDocument): Promise<void> {
        return this.baseService.update(designTemplateDocument);
    }

    public updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        return this.baseService.updateDesignThumbnailImage(designId, thumbnailContent, respond);
    }

    public findTemplateFolderById(templateFolderId: string): DesignTemplateFolderBase {
        return this.baseService.findTemplateFolderById(templateFolderId);
    }
}