import { Injectable } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { MenuServiceInjected } from '@profis-engineering/pe-ui-common/services/menu.common';
import { PickKeysByValue } from 'ts-essentials/dist/pick-keys-by-value';
import { DataService } from './data.service';
import { PropertyIdValue } from './design.service';
import { FavoritesService } from './favorites.service';
import { LocalizationService } from './localization.service';
import { MenuServiceExtensions } from './menu-service-extensions';
import { NumberService } from './number.service';
import { StaticMenuService } from './static-menu.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';
interface CodeListEntity {
    id: number;
    name?: string;
    nameKey?: string;
    image?: string;
    tooltipTitleKey?: string;
    tooltipKey?: string;
}

export interface CodeLists {
    region: CodeList[];
    unitLength: CodeList[];
    unitStress: CodeList[];
    unitForce: CodeList[];
    unitMoment: CodeList[];
    unitItemsPerArea: CodeList[];

    knowlageLevel: CodeList[];
    plasterThickness: CodeList[];
    numberOfAnchors: CodeList[];
}

class CodeListItem extends CodeList { }

export type CodeListType = keyof CodeLists;

export interface SetMenuOptions {
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceInjected<Design> {
    public codeLists: CodeLists = {} as CodeLists;
    constructor(
        private localizationService: LocalizationService,
        private staticMenuService: StaticMenuService,
        private dataService: DataService,
        private userService: UserService,
        private numberService: NumberService,
        private favoritesService: FavoritesService,
        private unitService: UnitService
    ) {
        super();
    }

    public init() {
        this.initCodeLists();
    }

    public setMenu(options: SetMenuOptions) {
        this.setMenuExtensions(new MenuServiceExtensions(
            options,
            this.staticMenuService,
            this.localizationService,
            this.unitService,
            this.numberService,
            this.userService,
            this.favoritesService,
            this.codeLists));

        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService),
        };
    }

    private initCodeLists() {
        this.initUnits();

        this.codeLists.region = this.toCodeList(this.dataService.appData, 'regions');
        this.codeLists.knowlageLevel = this.toCodeList(this.dataService.appData, 'knowlageLevels');
        this.codeLists.plasterThickness = this.toCodeList(this.dataService.appData, 'plasterThicknesses');
        this.codeLists.numberOfAnchors = this.toCodeList(this.dataService.appData, 'numberOfAnchors');
    }

    private initUnits() {
        this.codeLists.unitLength = this.toCodeList(this.dataService.units, 'length');
        this.codeLists.unitStress = this.toCodeList(this.dataService.units, 'stress');
        this.codeLists.unitForce = this.toCodeList(this.dataService.units, 'force');
        this.codeLists.unitMoment = this.toCodeList(this.dataService.units, 'moment');
        this.codeLists.unitItemsPerArea = this.toCodeList(this.dataService.units, 'itemsPerArea');
    }
    private toCodeList<T>(obj: T, property: PickKeysByValue<T, CodeListEntity[]>): CodeList[] {
        const values = obj?.[property] as CodeListEntity[] | undefined;

        if (values == null) {
            console.warn(`missing code list ${property as string}`);
            return [];
        }

        return values.map(x => new CodeListItem({
            id: x.id,
            name: x.name,
            nameResourceKey: x.nameKey,
            image: x.image,
            tooltipDisplayKey: x.tooltipKey,
            tooltipTitleDisplayKey: x.tooltipTitleKey
        }));
    }
}