<div class="content-group" *ngIf="isLoaded">

    <div class="content design-region">
        <p class="title-strike" l10n="MasonryRnf.ApplicationSettings.General"></p>

        <div class="content-controls region">
            <pe-dropdown [id]="regionDropdown.id" [title]="regionDropdown.title" [items]="regionDropdown.items"
                [selectedValue]="settings.regionId" (selectedValueChange)="onRegionDropdownChange($any($event).detail)"
                class="control" [disabled]="submitted"></pe-dropdown>
            <div class="design-type-status control-row info">
                <p [class.error]="!allowStatusOk">{{allowStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowQuickStart">

        <p class="title-strike" l10n="MasonryRnf.ApplicationSettings.Units"></p>

        <div class="content-controls">

            <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                [selectedValue]="settings.length" (selectedValueChange)="settings.length = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="stressDropdown.id" [items]="stressDropdown.items" [title]="stressDropdown.title"
                [selectedValue]="settings.stress" (selectedValueChange)="settings.stress = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="forceDropdown.id" [items]="forceDropdown.items" [title]="forceDropdown.title"
                [selectedValue]="settings.force" (selectedValueChange)="settings.force = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="momentDropdown.id" [items]="momentDropdown.items" [title]="momentDropdown.title"
                [selectedValue]="settings.moment" (selectedValueChange)="settings.moment = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="areaDropdown.id" [items]="areaDropdown.items" [title]="areaDropdown.title"
                [selectedValue]="settings.area" (selectedValueChange)="settings.area = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="itemsPerAreaDropdown.id" [items]="itemsPerAreaDropdown.items" [title]="itemsPerAreaDropdown.title"
                [selectedValue]="settings.itemsPerArea" (selectedValueChange)="settings.itemsPerArea = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="volumeDropdown.id" [items]="volumeDropdown.items" [title]="volumeDropdown.title"
                [selectedValue]="settings.volume" (selectedValueChange)="settings.volume = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="specificWeightDropdown.id" [items]="specificWeightDropdown.items" [title]="specificWeightDropdown.title"
                [selectedValue]="settings.specificWeight" (selectedValueChange)="settings.specificWeight = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>
        </div>

    </div>

</div>
