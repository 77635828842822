import Sortable, { Options } from 'sortablejs';

import {
    ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import {
    IMainMenuComponent, IMenu, MenuType
} from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, NavigationTabWidth
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { IDesignInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { NotificationType } from '@profis-engineering/pe-ui-common/entities/notifications';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { IntroJs } from '@profis-engineering/pe-ui-common/services/tour.common';

import { CollapsingControls } from '../../collapsing-controls';
import mainImage from '../../images/main-image.png';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { CoreApiService } from '../../services/core-api.service';
import {
    DesignDetails, DesignService, NumericalParameter, PropertyIdValue, ScopeCheckSeverity,
    TranslationFormat, TranslationParameter, TranslationParameterType
} from '../../services/design.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { TourService } from '../../services/tour.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import {
    INotificationsComponentInput, INotificationScopeCheck
} from '../notifications/notifications.component';
import { TrackingDetails, TrackingService } from '../../services/tracking.service';

const setTimeoutPromise = (handler: () => void, timeout?: number) => new Promise<void>((resolve, reject) => setTimeout(() => {
    try {
        handler();
        resolve();
    }
    catch (error) {
        reject(error);
    }
}, timeout));

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainComponent implements OnInit, OnDestroy {

    @ViewChild('mainMenuRef')
    public mainMenuComponentElementRef!: ElementRef<IMainMenuComponent>;

    public UnitPercent = UnitType.percent;
    public notificationComponentInputs!: INotificationsComponentInput;

    public commonRegion!: CommonRegion;
    public hideLeftMenu = false;
    public hideRightMenu = false;
    public rightSideLoaded = false;
    public modelViewZoom = 50;

    public sortableMenu3DRightOptions!: Options;
    public CollapsingControls = CollapsingControls;

    public mainImage = mainImage;

    public designDetails!: DesignDetails;
    public trackingDetails!: TrackingDetails;

    private designDetailsHistory: DesignDetails[] = [];
    private designDetailsHistoryIndex = -1;

    private userLogout = false;
    private openedVirtualTour?: IntroJs;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private modalService: ModalService,
        private applicationProviderService: ApplicationProviderService,
        private routingService: RoutingService,
        private tourService: TourService,
        private menuService: MenuService,
        private designService: DesignService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private coreApiService: CoreApiService,
        private translationFormatService: TranslationFormatService,
        private unitService: UnitService,
        private favoritesService: FavoritesService,
        private regionOrderService: RegionOrderService,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>,
        private trackingService: TrackingService
    ) {
        this.beforeLogout = this.beforeLogout.bind(this);
        this.openDesignSettings = this.openDesignSettings.bind(this);
        this.openSaveAsTemplate = this.openSaveAsTemplate.bind(this);
        this.startTour = this.startTour.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.openGeneralNotes = this.openGeneralNotes.bind(this);
        this.menuOpened = this.menuOpened.bind(this);
        this.hiltiDataPrivacyUrlOpened = this.hiltiDataPrivacyUrlOpened.bind(this);
        this.regionLinkOpened = this.regionLinkOpened.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.propertyChange = this.propertyChange.bind(this);
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-long-arrow-right-white',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-undo',
            'sprite-redo',
            'sprite-info',
            'sprite-warning',
        );

        this.designDetails = this.userService.design.designDetails;
        this.trackingDetails = this.userService.design.trackingDetails;
        this.pushDesignDetailsHistory(this.designDetails);

        this.menuService.setMenu({
            propertyChange: this.propertyChange
        });
        this.TrackDataOnTabClose();
        this.setNotificationComponentInputs();

        this.sortableMenu3DRightOptions = {
            handle: '.drag-handle-static',
            store: {
                get: () => {
                    return [];
                },
                set: async (sortable) => {
                    await this.regionOrderService.update(sortable.toArray(), MenuType.Menu3DRight);
                }
            },
            onSort: () => undefined
        };

        this.tourService.initialize();

        // show the page and then load controls after GLModel asynchronously
        setTimeoutPromise(() => this.initGlModel())
            .then(() => setTimeoutPromise(() => this.initMenu3d()))
            .then(() => setTimeoutPromise(() => this.initMenuTabSelection()))
            .then(() => setTimeoutPromise(() => this.initRightSide()))
            .then(() => setTimeoutPromise(() => this.startDesignTour()))
            .catch(err => console.error(err));
    }

    public ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.onBeforeUnloadEvent.bind(this), false);

        // no need to await
        this.designService.closeDesignOrDesignTemplate(this.designDetails, this.trackingDetails)
            .catch(error => console.error(error));

        // TODO FILIP: do we need this?
        // Fix for the issue that happens when you close the design before the introduction loads
        if (this.openedVirtualTour != null) {
            setTimeout(() => {
                document.querySelectorAll('.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-tooltip, .introjs-overlay, .introjs-disableInteraction')
                    .forEach(element => element.remove());
            });
        }
    }

    public get updatePending() {
        return this.userService.design?.pendingCalculation ?? false;
    }

    public get selectedLanguage() {
        return this.localizationService.selectedLanguage;
    }

    public get projectName() {
        return this.designDetails.projectName;
    }

    public get title() {
        let title = `${this.designDetails.isTemplate ? this.designDetails.templateName : this.designDetails.designName}`;

        if (!this.designDetails.isTemplate) {
            title += ` (${this.designDetails.projectName})`;
        }
        else{
            title += ` (${this.translate('Agito.Hilti.Profis3.Main.TemplateProjectName')})`;
        }

        title += `, ${this.translate(this.designDetails.region.nameKey)}`;

        return title;
    }

    public get undoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.translate('Agito.Hilti.Profis3.Main.Undo');
    }

    public get redoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.translate('Agito.Hilti.Profis3.Main.Redo');
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }

        // tracking counters
        this.trackingDetails.counters.designUndo++;

        this.designDetailsHistoryIndex--;
        this.designDetails = this.designDetailsHistory[this.designDetailsHistoryIndex];
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

        this.setNotificationComponentInputs();

        // sync designDetails with gl-model before we update it
        this.changeDetector.detectChanges();

        this.documentServiceUpdateDesign();
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }

        // tracking counters
        this.trackingDetails.counters.designRedo++;

        this.designDetailsHistoryIndex++;
        this.designDetails = this.designDetailsHistory[this.designDetailsHistoryIndex];
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

        this.setNotificationComponentInputs();

        // sync designDetails with gl-model before we update it
        this.changeDetector.detectChanges();

        this.documentServiceUpdateDesign();
    }

    public sortMenu3DRight(sortable: Sortable) {
        sortable.sort(this.favoritesService.menu3DRightOrder.map(String));
    }

    private documentServiceUpdateDesign() {
        // no await needed
        this.designService.documentServiceUpdateDesignOrDesignTemplate({
            designId: this.designDetails.designId,
            designName: this.designDetails.designName,
            projectId: this.designDetails.projectId,

            templateId: this.designDetails.templateId,
            templateName: this.designDetails.templateName,
            templateProjectId: this.designDetails.templateProjectId,

            designTypeId: this.designDetails.designTypeId,
            projectDesign: this.designDetails.projectDesign,
            regionId: this.designDetails.regionId,

            immediateRequest: false
        })
            .catch(error => console.error(error));

        this.documentServiceUpdateDesignImage();

        // no await needed
        this.designService.trackOnDesignOrTemplateChange({
            designDetails: this.designDetails,
            trackingDetails: this.trackingDetails,

            immediateRequest: false
        })
            .catch(error => console.error(error));
    }

    private trackingServiceChange() {
        // no await needed
        this.designService.trackOnDesignOrTemplateChange({
            designDetails: this.designDetails,
            trackingDetails: this.trackingDetails,

            immediateRequest: false
        })
            .catch(error => console.error(error));
    }

    private documentServiceUpdateDesignImage() {
        // do nothing, no image is needed for this module
    }

    public canUndo() {
        return this.designDetailsHistoryIndex > 0 &&
            !this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.designDetails.regionId);
    }

    public canRedo() {
        return this.designDetailsHistoryIndex < this.designDetailsHistory.length - 1 &&
            !this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.designDetails.regionId);
    }

    public toggleLeftMenu() {
        this.hideLeftMenu = !this.hideLeftMenu;
    }

    public toggleRightMenu() {
        this.hideRightMenu = !this.hideRightMenu;
    }

    public TrackDataOnTabClose() {
        window.addEventListener('beforeunload', this.onBeforeUnloadEvent.bind(this), false);
    }

    public getDesignInfoForDesignType(): IDesignInfo {
        return this.applicationProviderService.getDesignInfo()
            .find(x => x.designTypeId == this.designDetails.designTypeId) as IDesignInfo;
    }

    public openDesignSettings() {
        const designInfo = this.getDesignInfoForDesignType();
        this.modalService.openAddEditDesignFromModule({
            // TODO FILIP: check what we need from this design properties
            design: {
                id: this.designDetails.designId,
                name: this.designDetails.isTemplate ? this.designDetails.templateName : this.designDetails.designName,
                projectId: this.designDetails.projectId,
                projectName: this.designDetails.projectName,
                region: this.designDetails.commonRegion,
                designType: this.designDetails.designTypeId,
                displayDesignType: this.designDetails.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                designTemplateDocumentId: this.designDetails.templateId,
                design: this.userService.design
            },
            addEditType: AddEditType.edit,
            afterOpenInstructions: undefined,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: async (_designDetails) => {
                const designDetails = _designDetails as DesignDetails;

                this.designDetailsChange(designDetails);
            }
        });
    }

    public startTour() {
        this.modalService.openVirtualTourPopup(this.selectTab);
    }

    public async openSaveAsTemplate() {
        this.modalService.openSaveAsTemplate({
            designTemplateDocument: this.getDesignTemplateDocument(),
            thumbnailId: this.designDetails.designId,
            onTemplateSaved: this.onTemplateSaved.bind(this)
        });
    }

    public get mainMenuComponent() {
        return this.mainMenuComponentElementRef?.nativeElement;
    }

    public openGeneralNotes() {
        // TODO: BUDQBP-23561
        // TODO FILIP: fix

        const copyText = this.translate('Agito.Hilti.Profis3.GeneralNotes.CopyText');
        const text = this.translate('Agito.Hilti.Profis3.GeneralNotes.DisplayText');

        this.modalService.openGeneralNotes(text, copyText);
    }

    public menuOpened() {
        this.trackingDetails.counters.headerMenuOpened++;
        this.trackingServiceChange();
    }

    public hiltiDataPrivacyUrlOpened() {
        this.trackingDetails.counters.headerOnlineTechnicalInformation++;
        this.trackingServiceChange();
    }

    public regionLinkOpened() {
        this.trackingDetails.counters.headerOnlineTechnicalInformation++;
        this.trackingServiceChange();
    }

    public get selectedMenu(): IMenu {
        return this.mainMenuComponent?.getSelectedMenu() ?? {} as IMenu;
    }

    public get hasExtendedWidth() {
        if (this.selectedMenu?.tabs == null)
            return false;

        const selectedTab = this.selectedMenu?.tabs[this.selectedMenu.selectedTab];
        const canExtend = selectedTab?.width == NavigationTabWidth.Extended;
        return canExtend && !this.hideLeftMenu;
    }

    public startDesignTour() {
        if (this.userService == null || this.userService.design == null) {
            return;
        }

        // Get all tours that are available for design and not yet seen.
        const availableTours = this.tourService.getVirtualTours()
            .filter(x => x.order != null)
            .filter(x => SafeFunctionInvokerHelper.safeInvoke(x.isAvailable, false))
            .filter(x => !SafeFunctionInvokerHelper.safeInvoke(x.alreadySeen, false))
            .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));

        // First tour shall start immediately, others should start after previous one is completed.
        const startNextTour = () => {
            const tour = availableTours.shift();
            if (tour == null) {
                return;
            }

            this.openedVirtualTour = tour.openTour(this.selectTab.bind(this));

            // oncomplete is triggered if Got It button is clicked on last step, onexit is triggered if dismiss button is clicked on any other step
            const exitFn = () => {
                this.openedVirtualTour = undefined;
                tour.markAsSeen()
                    .catch(error => console.error(error));
                startNextTour();
            };
            this.openedVirtualTour.oncomplete(exitFn.bind(this));
            this.openedVirtualTour.onexit(exitFn.bind(this));
        };

        startNextTour();
    }

    private pushDesignDetailsHistory(designDetails: DesignDetails) {
        this.designDetailsHistoryIndex++;
        this.designDetailsHistory.splice(this.designDetailsHistoryIndex);
        this.designDetailsHistory.push(designDetails);

        if (this.designDetailsHistoryIndex > 50) {
            this.designDetailsHistoryIndex--;
            this.designDetailsHistory.splice(0, 1);
        }
    }

    private initMenu3d() {
        this.mainMenuComponent?.initMenu3d(
            this.userService.design,
            this.tabSelected,
            {

            } as unknown as Record<'', (navigationControl?: BaseControl) => void>);
    }

    private initMenuTabSelection() {
        if (!this.favoritesService.hasFavorites(this.userService.design.designTypeId)) {
            this.selectTabById('masonry-rnf-design-tab');
        }
    }

    private tabSelected() {
        this.hideLeftMenu = false;
    }

    private initGlModel() {
        this.documentServiceUpdateDesignImage();
    }

    public async propertyChange(propertyChanges: PropertyIdValue[]): Promise<void> {
        if (propertyChanges == null || propertyChanges.length == 0) {
            return;
        }

        try {
            this.userService.design.pendingCalculation = this.coreApiService.isLongRunning;
            this.refreshHeader();

            const updateDesignResult = await this.designService.updateDesignOrDesignTemplate({
                designTypeId: this.designDetails.designTypeId,
                designId: this.designDetails.designId,
                designName: this.designDetails.designName,
                projectId: this.designDetails.projectId,

                templateId: this.designDetails.templateId,
                templateName: this.designDetails.templateName,
                templateProjectId: this.designDetails.templateProjectId,

                projectDesign: this.designDetails.projectDesign,
                properties: propertyChanges,

                trackingDetails: this.trackingDetails,

                immediateRequest: false
            });

            // skip if we have pending updates
            if (updateDesignResult?.resetAction) {
                this.resetAction();
            }
            else if (updateDesignResult?.designDetails != null) {
                this.designDetailsChange(updateDesignResult.designDetails);
            }

        }
        catch (error) {
            console.error(error);

            // if we don't have a successful call reload UI with the same values (revert of the change)

            // menu has this strange async update and error popup will steal focus which triggers menu update
            // so we wait for this async stuff to finish before we revert the changes in the menu
            await new Promise<void>((resolve, reject) => setTimeout(() => {
                try {
                    this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

                    this.userService.design.pendingCalculation = false;
                    this.refreshHeader();

                    resolve();
                }
                catch (error) {
                    reject(error);
                }
            }));

            throw error;
        }
    }

    private resetAction() {
        this.userService.design.pendingCalculation = false;
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);
    }

    private designDetailsChange(designDetails: DesignDetails) {
        this.userService.design.pendingCalculation = false;
        this.refreshHeader();

        this.designDetails = designDetails;
        this.pushDesignDetailsHistory(this.designDetails);
        this.designService.updatePeDesignObject(this.userService.design, designDetails);

        this.setNotificationComponentInputs();

        // sync designDetails with gl-model before we update it
        this.changeDetector.detectChanges();

        this.documentServiceUpdateDesignImage();
    }

    private refreshHeader() {
        // TODO FILIP: remove this hack once common header is fixed
        // trigger common header refresh
        this.openGeneralNotes = () => this.openGeneralNotes();
    }

    public loadsVisible() {
        return true;
    }

    private initRightSide() {
        this.rightSideLoaded = true;
    }

    private getDesignTemplateDocument(): IDesignTemplateDocument {
        return {
            designTypeId: this.designDetails.designTypeId,
            // TODO FILIP: why does pe-ui need design standard?
            designStandardId: 0,
            regionId: this.designDetails.regionId,
            anchorName: '',
            approvalNumber: '',
            projectDesign: JSON.stringify(this.designDetails.projectDesign)
        };
    }

    private async onTemplateSaved() {
        await this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    private async onBeforeUnloadEvent() {
        if (!this.userLogout) {
            await this.trackingService.trackOnCloseBrowserUnloadEvent(this.designDetails, this.trackingDetails, this.designDetails.isTemplate);
        }
    }

    public async beforeLogout() {
        this.userLogout = true; // we set this to true so we ignore beforeunload event
        await this.processDesignClose();
    }

    public async processDesignClose(): Promise<void> {
        const licensePromise = this.userService.releaseAllFloatingLicenses(true);
        const trackingPromise = this.designDetails.isTemplate
            ? this.trackingService.trackOnTemplateClose(this.designDetails, this.trackingDetails)
            : this.trackingService.trackOnDesignClose(this.designDetails, this.trackingDetails);
        await Promise.all([trackingPromise, licensePromise]);
    }

    private selectTab(tab: string) {
        this.selectTabById(`tab-${tab}`);

        this.hideLeftMenu = false;
    }

    private selectTabById(tab: string) {
        this.mainMenuComponent?.selectTab(tab);
    }

    private translate(key: string) {
        return this.localizationService.getString(key);
    }

    private get hasScopeChecks() {
        return this.scopeChecks.length > 0;
    }

    private get scopeChecks() {
        return this.designDetails.calculationResult?.scopeCheckResults?.failedScopeChecks ?? [];
    }

    private setNotificationComponentInputs() {
        const scopeChecksOrInvalidCalculation = this.hasScopeChecks || this.designDetails.calculationResult == null;

        this.notificationComponentInputs = {
            isVisible: () => {
                return scopeChecksOrInvalidCalculation;
            },
            isInfoMessageVisible: () => {
                return true;
            },
            notifications: [],
            scopeChecks: this.notificationScopeChecks
        };
    }

    public get notificationScopeChecks(): INotificationScopeCheck[] {
        if (this.designDetails.calculationResult == null) {
            return this.getCalculationErrorScopeCheck();
        }

        return this.designDetails?.calculationResult?.scopeCheckResults?.failedScopeChecks?.map((sc): INotificationScopeCheck => ({
            type: this.getNotificationType(sc.severity),
            message: this.getScopeCheckHtml(sc.message),
            hasSupportButton: () => false,
            supportButtonClick: undefined!,
            actionButtons: []
        })) ?? [];
    }

    public getNotificationType(severity: ScopeCheckSeverity) {
        switch (severity) {
            case ScopeCheckSeverity.Info:
                return NotificationType.info;
            case ScopeCheckSeverity.Error:
                return NotificationType.alert;
        }
    }

    public getScopeCheckHtml(scopeCheckMessage: TranslationFormat): string {
        const transformedParams = this.translationFormatService.transformTranslationParameters(
            scopeCheckMessage.translationParameters,
            true,
            undefined,
            this.getNumericScopeCheckHtml.bind(this)
        );

        const html = this.translationFormatService.getLocalizedStringWithTranslationFormat(
            scopeCheckMessage,
            true,
            transformedParams
        );

        return html?.replace(/τ/g, '<span class="tauFontSmall">τ</span>') ?? '';
    }

    private getNumericScopeCheckHtml(parameter: TranslationParameter, roundValue: boolean) {
        if (parameter.parameterType !== TranslationParameterType.Numerical || !roundValue) {
            // Handle only Numerical parameters with rounding
            return null;
        }

        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.value == null) {
            return null;
        }

        return this.getNumericScopeCheckHtmlBase(numericalParameter.value, numericalParameter.unitGroup, numericalParameter.additionalPrecision as number);
    }

    private getNumericScopeCheckHtmlBase(numberValue: number, unitGroup: UnitGroup, additionalPrecision: number) {
        let unit = UnitType.None;
        if (unitGroup) {
            unit = this.unitService.getDefaultUnit(unitGroup);
        }

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const precision = this.unitService.getPrecision(unit) + additionalPrecision;

        numberValue = this.getConvertedUnitValue(numberValue, unit, unitGroup);
        let displayedUnitValue = this.unitService.formatNumber(numberValue, precision);
        let exactUnitValue = this.unitService.formatNumber(numberValue, maxPrecision);

        if (displayedUnitValue.length >= exactUnitValue.length) {
            return null;
        }

        // Displayed with less decimals than internally used!
        displayedUnitValue = this.unitService.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);

        if (unitGroup) {
            displayedUnitValue = this.unitService.appendUnitToValueString(numberValue, displayedUnitValue, unit);
            exactUnitValue = this.unitService.appendUnitToValueString(numberValue, exactUnitValue, unit);
        }

        return `<span class="additional-info" title="${exactUnitValue}">${displayedUnitValue}</span>`;
    }

    private getConvertedUnitValue(numberValue: number, unit: UnitType, unitGroup: UnitGroup) {
        if (unitGroup) {
            const internalUnit = this.unitService.getInternalUnit(unitGroup);
            return this.unitService.convertUnitValueArgsToUnit(numberValue, internalUnit, unit);
        }

        return numberValue;
    }

    public getCalculationErrorScopeCheck() {
        return [{
            type: NotificationType.alert,
            message: this.translate('MasonryRnf.ScopeCheck.ScErrorUnhandled'),
            hasSupportButton: () => { return true; },
            supportButtonClick: () => { this.modalService.openSupport(); }
        } as INotificationScopeCheck];
    }
}
