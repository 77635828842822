import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { MarkRequired } from 'ts-essentials';
import { PickKeysByValue } from 'ts-essentials/dist/pick-keys-by-value';
import { CommonCodeListService } from './common-code-list.service';
import { Properties } from './design.service';
import { CoreApiService } from './core-api.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { environment } from '../../environments/environment';
import { Unit as UnitEnum } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

export interface AppData {
    propertyDetails: ApiAppPropertyDetails;
    regions: { id: number }[];

    knowlageLevels: KnowlageLevel[];
    plasterThicknesses: PlasterThickness[];
    numberOfAnchors: NumberOfAnchors[];
}

export type AppPropertyId = keyof Pick<Properties,
    'unitLength' |
    'unitStress' |
    'unitForce' |
    'unitMoment' |
    'unitArea' |
    'unitItemsPerArea' |
    'unitVolume' |
    'unitSpecificWeight' |

    'wallThickness' |
    'wallWidth' |
    'wallHeight' |

    'compressiveStrength' |
    'specificWeight' |
    'compressiveStrength' |
    'shearStrengthDiagonalCrack' |
    'shearStrengthStairSteppedCrack' |
    'knowledgeLevel' |
    'partialSafetyFactor' |

    'confidenceFactorCalculated' |
    'compressiveStrengthCalculated' |
    'shearStrengthDiagonalCrackCalculated' |
    'shearStrengthStairSteppedCrackCalculated' |

    'axialCompressiveLoad' |
    'inPlaneBendingMoment' |
    'outOfPlaneBendingMoment' |
    'shearLoad' |

    'plasterThickness' |
    'numberOfAnchors' |
    'plasterSpecificWeight' |
    'plasterCompressiveStrength' |

    'anchorDiameterCalculated' |
    'embedmentDepthCalculated' |
    'extendingProtrudingLengthCalculated' |
    'outsideBendRadiusCalculated' |
    'rebarStrainghtLengthCalculated' |
    'horizontalSpacingCalculated' |
    'verticalSpacingCalculated' |
    'anchorMinEdgeDistanceCalculated' |
    'shiftBetweenAnchorsCalculated' |
    'numberOfAnchorsRowsCalculated' |
    'numberOfAnchorsColumnsCalculated' |
    'totalNumberOfAnchorsCalculated' |
    'numberOfAnchorsPerAreaCalculated' |
    'plasterElaticModulusCalculated'
>;

export type PropertyDetailDefault = MarkRequired<PropertyDetail, 'defaultValue'>;
export type PropertyDetailDefaultMinMax = MarkRequired<PropertyDetail, 'defaultValue' | 'minValue' | 'maxValue'>;
export type PropertyDetailDefaultMinMaxPrecision = MarkRequired<PropertyDetail, 'defaultValue' | 'minValue' | 'maxValue' | 'precision'>;

/** property detail per property - some properties like units always have defaultValue so we remove the optional marker */
export type AppPropertyDetailMap = Pick<{
    'unitLength': PropertyDetailDefault;
    'unitStress': PropertyDetailDefault;
    'unitForce': PropertyDetailDefault;
    'unitMoment': PropertyDetailDefault;
    'unitArea': PropertyDetailDefault;
    'unitItemsPerArea': PropertyDetailDefault;
    'unitVolume': PropertyDetailDefault;
    'unitSpecificWeight': PropertyDetailDefault;

    'wallThickness': PropertyDetailDefaultMinMaxPrecision;
    'wallWidth': PropertyDetailDefaultMinMax;
    'wallHeight': PropertyDetailDefaultMinMax;

    'compressiveStrength': PropertyDetailDefaultMinMax;
    'specificWeight': PropertyDetailDefaultMinMax;
    'shearStrengthDiagonalCrack': PropertyDetailDefaultMinMax;
    'shearStrengthStairSteppedCrack': PropertyDetailDefaultMinMax;
    'knowledgeLevel': PropertyDetailDefault;
    'partialSafetyFactor': PropertyDetailDefaultMinMax;

    'confidenceFactorCalculated': PropertyDetailDefaultMinMax;
    'compressiveStrengthCalculated': PropertyDetailDefaultMinMax;
    'shearStrengthDiagonalCrackCalculated': PropertyDetailDefaultMinMax;
    'shearStrengthStairSteppedCrackCalculated': PropertyDetailDefaultMinMax;

    'axialCompressiveLoad': PropertyDetailDefaultMinMax;
    'inPlaneBendingMoment': PropertyDetailDefaultMinMax;
    'outOfPlaneBendingMoment': PropertyDetailDefaultMinMax;
    'shearLoad': PropertyDetailDefaultMinMax;
    'plasterThickness': PropertyDetailDefault;
    'numberOfAnchors': PropertyDetailDefault;
    'plasterSpecificWeight': PropertyDetailDefaultMinMax;
    'plasterCompressiveStrength': PropertyDetailDefaultMinMax;

    'anchorDiameterCalculated': PropertyDetailDefault;
    'embedmentDepthCalculated': PropertyDetailDefault;
    'extendingProtrudingLengthCalculated': PropertyDetailDefault;
    'outsideBendRadiusCalculated': PropertyDetailDefault;
    'rebarStrainghtLengthCalculated': PropertyDetailDefault;
    'horizontalSpacingCalculated': PropertyDetailDefault;
    'verticalSpacingCalculated': PropertyDetailDefault;
    'anchorMinEdgeDistanceCalculated': PropertyDetailDefault;
    'shiftBetweenAnchorsCalculated': PropertyDetailDefault;
    'numberOfAnchorsRowsCalculated': PropertyDetailDefault;
    'numberOfAnchorsColumnsCalculated': PropertyDetailDefault;
    'totalNumberOfAnchorsCalculated': PropertyDetailDefault;
    'numberOfAnchorsPerAreaCalculated': PropertyDetailDefault;
    'plasterElaticModulusCalculated': PropertyDetailDefault;
},
    AppPropertyId>;

export type ApiAppPropertyId = keyof Pick<AppPropertyDetailMap,
    'unitLength' |
    'unitStress' |
    'unitForce' |
    'unitMoment' |
    'unitArea' |
    'unitItemsPerArea' |
    'unitVolume' |
    'unitSpecificWeight' |

    'wallThickness' |
    'wallWidth' |
    'wallHeight' |

    'compressiveStrength' |
    'specificWeight' |
    'shearStrengthDiagonalCrack' |
    'shearStrengthStairSteppedCrack' |
    'knowledgeLevel' |
    'partialSafetyFactor' |

    'confidenceFactorCalculated' |
    'compressiveStrengthCalculated' |
    'shearStrengthDiagonalCrackCalculated' |
    'shearStrengthStairSteppedCrackCalculated' |

    'axialCompressiveLoad' |
    'inPlaneBendingMoment' |
    'outOfPlaneBendingMoment' |
    'shearLoad' |

    'plasterThickness' |
    'numberOfAnchors' |
    'plasterSpecificWeight' |
    'plasterCompressiveStrength' |

    'anchorDiameterCalculated' |
    'embedmentDepthCalculated' |
    'extendingProtrudingLengthCalculated' |
    'outsideBendRadiusCalculated' |
    'rebarStrainghtLengthCalculated' |
    'horizontalSpacingCalculated' |
    'verticalSpacingCalculated' |
    'anchorMinEdgeDistanceCalculated' |
    'shiftBetweenAnchorsCalculated' |
    'numberOfAnchorsRowsCalculated' |
    'numberOfAnchorsColumnsCalculated' |
    'totalNumberOfAnchorsCalculated' |
    'numberOfAnchorsPerAreaCalculated' |
    'plasterElaticModulusCalculated'
>;
export type ApiAppPropertyDetails = Record<ApiAppPropertyId, ApiPropertyDetailGroup[]>;
export interface ApiPropertyDetailGroup {
    regionId: number;
    propertyDetail: PropertyDetail;
}

export interface PropertyDetail {
    defaultValue?: number;
    minValue?: number;
    maxValue?: number;
    precision?: number;
    allowedValues?: number[];
    disabled?: boolean;
    hidden?: boolean;
}

export interface Region {
    id: number;
    nameKey: string;
}

export interface KnowlageLevel {
    id: number;
    name: string;
}

export interface PlasterThickness {
    id: number;
    name: string;
}
export interface NumberOfAnchors {
    id: number;
    name: string;
}

export interface Unit {
    id: number;
    name: string;
}

type PropertyDetails = Record<number, Record<AppPropertyId, PropertyDetail>>;

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public regions!: Region[];
    public regionsById!: Record<number, Region>;

    public allRegions!: Region[];
    public allRegionsById!: Record<number, Region>;

    public units!: {
        length: Unit[];
        lengthById: Record<number, Unit>;
        stress: Unit[];
        stressById: Record<number, Unit>;
        force: Unit[];
        forceById: Record<number, Unit>;
        moment: Unit[];
        momentById: Record<number, Unit>;
        area: Unit[];
        areaById: Record<number, Unit>;
        itemsPerArea: Unit[];
        itemsPerAreaById: Record<number, Unit>;
        volume: Unit[];
        volumeById: Record<number, Unit>;
        specificWeight: Unit[];
        specificWeightById: Record<number, Unit>;
    };

    public appData!: AppData;
    private propertyDetails!: PropertyDetails;

    constructor(
        private commonCodeListService: CommonCodeListService,
        private coreApiService: CoreApiService,
        private featureVisibilityService: FeatureVisibilityService
    ) { }

    public async loadData() {
        this.appData = await this.coreApiService.api.app.data();

        this.initRegions();
        this.initUnits();

        this.initProperties();
    }

    /**
     * Get PropertyDetail for the specified regionId and propertyId.
     * If no PropertyDetail is found for the specified regionId we return default PropertyDetail that are not connected with a region (regionId == 0).
     */
    public getPropertyDetail<K extends keyof AppPropertyDetailMap>(regionId: number, propertyId: K): AppPropertyDetailMap[K] {
        // for now we only filter by regionId
        // if in the future we add other filters (design standard?) we have to update this code
        const propertyDetail = this.propertyDetails[regionId]?.[propertyId];

        // try with default region
        if (propertyDetail == null && regionId != 0) {
            return this.getPropertyDetail(0, propertyId);
        }

        return propertyDetail as AppPropertyDetailMap[K];
    }

    private initProperties() {
        this.propertyDetails = {};

        for (const _propertyId in this.appData.propertyDetails) {
            const propertyId = _propertyId as ApiAppPropertyId;
            const propertyDetailGroups = this.appData.propertyDetails[propertyId];

            for (const propertyDetailGroup of propertyDetailGroups) {
                (this.propertyDetails[propertyDetailGroup.regionId] = this.propertyDetails[propertyDetailGroup.regionId] ?? {})[propertyId] = propertyDetailGroup.propertyDetail;
            }
        }

        // add units as properties from common code list
        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        const propertiesMapping = [
            { propertyId: 'unitLength', unitsKey: 'length', defaultProperty: 'defaultUnitLength', defaultUnit: UnitEnum.mm },
            { propertyId: 'unitStress', unitsKey: 'stress', defaultProperty: 'defaultUnitStress', defaultUnit: UnitEnum.Nmm2 },
            { propertyId: 'unitForce', unitsKey: 'force', defaultProperty: 'defaultUnitForce', defaultUnit: UnitEnum.kN },
            { propertyId: 'unitMoment', unitsKey: 'moment', defaultProperty: 'defaultUnitMoment', defaultUnit: UnitEnum.kNm },
            // Static mappings for properties with fixed default values
            { propertyId: 'unitArea', unitsKey: 'area', defaultUnit: UnitEnum.m2 },
            { propertyId: 'unitItemsPerArea', unitsKey: 'itemsPerArea', defaultUnit: UnitEnum.item_m2 },
            { propertyId: 'unitVolume', unitsKey: 'volume', defaultUnit: UnitEnum.m3 },
            { propertyId: 'unitSpecificWeight', unitsKey: 'specificWeight', defaultUnit: UnitEnum.kN_m3 },
        ];
        for (const commonRegion of commonRegions) {
            const region = this.regionsById[commonRegion.id];

            if (region != null) {
                // Iterate over the mapping to set default values
                propertiesMapping.forEach(({ propertyId, unitsKey, defaultProperty, defaultUnit }) => {
                    const units = this.units[unitsKey as keyof typeof this.units] as Unit[];
                    const defaultValue = defaultProperty != undefined ? getRegionDefault(commonRegion, defaultProperty, units) ?? defaultUnit : defaultUnit;
                    addUnitPropertyDetail(this.propertyDetails, commonRegion, propertyId as AppPropertyId, defaultValue);
                });
            }
        }

        function getRegionDefault(commonRegion: CommonRegion, defaultProperty: string, allowedValues: Unit[]) {
            const defaultValue = commonRegion[defaultProperty as keyof CommonRegion] as number | null | undefined;
            return allowedValues.some(x => x.id == defaultValue) ? defaultValue : undefined;
        }

        function addUnitPropertyDetail(propertyDetails: PropertyDetails, commonRegion: CommonRegion, propertyId: AppPropertyId, defaultValue: UnitEnum) {
            (propertyDetails[commonRegion.id] = propertyDetails[commonRegion.id] ?? {})[propertyId] = { defaultValue };
        }
    }

    private toDictionary<T, P extends keyof T>(values: T[], property: P) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return values.reduce((valuesByProperty, value) => { valuesByProperty[value[property]] = value; return valuesByProperty; }, {} as any) as Record<T[P] extends string | number | symbol ? T[P] : never, T>;
    }

    private groupById<T, P extends PickKeysByValue<T, number[]>>(values: T[], property: P, ids: number[]) {
        const valuesById: Record<number, T[]> = {};

        for (const id of ids) {
            valuesById[id] = [];
        }

        for (const value of values) {
            for (const id of value[property] as number[]) {
                valuesById[id].push(value);
            }
        }

        return valuesById;
    }

    private initRegions(): void {
        const configRegionIds = environment.featuresConfig?.find(x => x.key == 'Regions')?.value ?? [];
        const allowedRegionIds = this.featureVisibilityService.getFeatureValue<number[]>('MasonryReinforcement_Regions', configRegionIds);

        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        // all regions
        this.allRegions = commonRegions
            .map((x): Region => ({
                id: x.id,
                // take the translation key from pe-ui
                nameKey: x.nameResourceKey as string
            }));
        this.allRegionsById = this.toDictionary(this.allRegions, 'id');

        // MasonryReinforcement regions
        this.regions = this.allRegions.filter(x => allowedRegionIds.includes(x.id));
        this.regionsById = this.toDictionary(this.regions, 'id');

        // code list regions
        this.appData.regions = this.regions.map(x => ({ id: x.id }));
    }

    private initUnits(): void {

        const area = [
            { id: UnitEnum.mm2, name: 'mm²' },
            { id: UnitEnum.cm2, name: 'cm²' },
            { id: UnitEnum.m2, name: 'm²' },
            { id: UnitEnum.inch2, name: 'in²' },
            { id: UnitEnum.ft2, name: 'ft²' }
        ] as Unit[];


        const itemsPerArea = [
            { id: UnitEnum.item_mm2, name: '1/mm²' },
            { id: UnitEnum.item_cm2, name: '1/cm²' },
            { id: UnitEnum.item_m2, name: '1/m²' },
            { id: UnitEnum.item_inch2, name: '1/in²' },
            { id: UnitEnum.item_ft2, name: '1/ft²' }
        ] as Unit[];

        const volumeUnit = [
            { id: UnitEnum.mm3, name: 'mm³' },
            { id: UnitEnum.cm3, name: 'cm³' },
            { id: UnitEnum.m3, name: 'm³' },
            { id: UnitEnum.inch3, name: 'in³' },
            { id: UnitEnum.ft3, name: 'ft³' }
        ] as Unit[];

        const specificWeightUnit = [
            { id: UnitEnum.N_mm3, name: 'N/mm³' },
            { id: UnitEnum.kN_m3, name: 'kN/m³' },
            { id: UnitEnum.lbf_ft3, name: 'lbf/ft³' }
        ] as Unit[];

        const supportedLengths = [UnitEnum.mm, UnitEnum.cm, UnitEnum.m, UnitEnum.inch, UnitEnum.ft];
        const lengths = (this.commonCodeListService.commonCodeLists[CommonCodeList.UnitLength] as Unit[]).filter(x => supportedLengths.includes(x.id));

        const supportedStress = [UnitEnum.Nmm2, UnitEnum.PSI, UnitEnum.kNm2, UnitEnum.KSI, UnitEnum.kgfcm2];
        const stress = (this.commonCodeListService.commonCodeLists[CommonCodeList.UnitStress] as Unit[]).filter(x => supportedStress.includes(x.id));

        const supportedForce = [UnitEnum.N, UnitEnum.daN, UnitEnum.kN, UnitEnum.lb, UnitEnum.Kip, UnitEnum.kgf];
        const force = (this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForce] as Unit[]).filter(x => supportedForce.includes(x.id));

        const supportedMoment = [UnitEnum.Nm, UnitEnum.daNm, UnitEnum.kNm, UnitEnum.in_lb, UnitEnum.ft_lb, UnitEnum.in_kip, UnitEnum.ft_kip, UnitEnum.kgfcm];
        const moment = (this.commonCodeListService.commonCodeLists[CommonCodeList.UnitMoment] as Unit[]).filter(x => supportedMoment.includes(x.id));

        //const supportedArea = [UnitType.mm2, UnitType.cm2, UnitType.m2, UnitType.inch2, UnitType.ft2];
        //const area = (this.commonCodeListService.commonCodeLists[CommonCodeList.UnitArea] as Unit[]).filter(x => supportedArea.includes(x.id));

        this.units = {
            length: lengths,
            lengthById: this.toDictionary(lengths, 'id'),

            stress: stress,
            stressById: this.toDictionary(stress, 'id'),

            force: force,
            forceById: this.toDictionary(force, 'id'),

            moment: moment,
            momentById: this.toDictionary(moment, 'id'),

            area: area,
            areaById: this.toDictionary(area, 'id'),

            itemsPerArea: itemsPerArea,
            itemsPerAreaById: this.toDictionary(itemsPerArea, 'id'),

            volume: volumeUnit,
            volumeById: this.toDictionary(volumeUnit, 'id'),

            specificWeight: specificWeightUnit,
            specificWeightById: this.toDictionary(specificWeightUnit, 'id')
        };
    }
}
