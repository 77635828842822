import { Injectable } from '@angular/core';
import { BrowserServiceInjected } from '@profis-engineering/pe-ui-common/services/browser.common';

@Injectable({
    providedIn: 'root'
})
export class BrowserService extends BrowserServiceInjected {
    public blobToBase64(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64WithMetadata = reader.result as string;
                const base64MetadataIndex = base64WithMetadata.lastIndexOf(',');
                const base64 = base64WithMetadata.substring(base64MetadataIndex + 1);

                resolve(base64);
            };
            reader.readAsDataURL(blob);
        });
    }

    public blobToDataUrl(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }
}
