<div class="box-section box-section-white">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static"
            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage">
            <span class="bar-title" [innerHtml]="'MasonryRnf.Utilizations' | l10n: localizationService.selectedLanguage"></span>
        </div>

        <pe-collapse
            [container]="'results-table'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="results-table">
      <table>
        <tbody>
            <tr>
                <th></th>
                <th>NTC</th>
                <th>HILTI</th>
            </tr>
            <tr>
              <td><span class="equation"  [ngbTooltip]="'MasonryRnf.Utilizations.AxialLoad' | l10n: localizationService.selectedLanguage">N<sub>rd,retro</sub></span></td>
              <td [class.not-verified]="!isVerified(axialLoadNtcResult)" [class.verified]="isVerified(axialLoadNtcResult)">{{ format(axialLoadNtcResult, 0) }}</td>
              <td [class.not-verified]="!isVerified(axialLoadResult)" [class.verified]="isVerified(axialLoadResult)">{{ format(axialLoadResult, 0) }}</td>
            </tr>
            <tr>
              <td><span class="equation" [ngbTooltip]="'MasonryRnf.Utilizations.InPlaneBendingMoment' | l10n: localizationService.selectedLanguage">M<sub>rd,in,retro</sub></span></td>
              <td [class.not-verified]="!isVerified(inPlaneBendingMomentNtcResult)" [class.verified]="isVerified(inPlaneBendingMomentNtcResult)">{{ format(inPlaneBendingMomentNtcResult, 0) }}</td>
              <td [class.not-verified]="!isVerified(inPlaneBendingMomentResult)" [class.verified]="isVerified(inPlaneBendingMomentResult)">{{ format(inPlaneBendingMomentResult, 0) }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.Utilizations.OutOfPlaneBendingMoment' | l10n: localizationService.selectedLanguage">M<sub>rd,out,retro</sub></span></td>
                <td [class.not-verified]="!isVerified(outOfPlaneBendingMomentNtcResult)" [class.verified]="isVerified(outOfPlaneBendingMomentNtcResult)">{{ format(outOfPlaneBendingMomentNtcResult, 0) }}</td>
                <td [class.not-verified]="!isVerified(outOfPlaneBendingMomentResult)" [class.verified]="isVerified(outOfPlaneBendingMomentResult)">{{ format(outOfPlaneBendingMomentResult, 0) }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.Utilizations.ShearResistanceDiagonalCracking' | l10n: localizationService.selectedLanguage">V<sub>rd,1,retro</sub></span></td>
                <td [class.not-verified]="!isVerified(shearResistanceDiagonalCrackingNtcResult)" [class.verified]="isVerified(shearResistanceDiagonalCrackingNtcResult)">{{ format(shearResistanceDiagonalCrackingNtcResult, 0) }}</td>
                <td [class.not-verified]="!isVerified(shearResistanceDiagonalCrackingResult)" [class.verified]="isVerified(shearResistanceDiagonalCrackingResult)">{{ format(shearResistanceDiagonalCrackingResult, 0) }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.Utilizations.ShearResistanceStairStepped' | l10n: localizationService.selectedLanguage">V<sub>rd,2,retro</sub></span></td>
                <td [class.not-verified]="!isVerified(shearResistanceStairSteppedNtcResult)" [class.verified]="isVerified(shearResistanceStairSteppedNtcResult)">{{ format(shearResistanceStairSteppedNtcResult, 0) }}</td>
                <td [class.not-verified]="!isVerified(shearResistanceStairSteppedResult)" [class.verified]="isVerified(shearResistanceStairSteppedResult)">{{ format(shearResistanceStairSteppedResult, 0) }}</td>
            </tr>
        </tbody>
    </table>
</div>