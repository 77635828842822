import { Injectable } from '@angular/core';
import { TourServiceInjected } from '@profis-engineering/pe-ui-common/services/tour.common';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { UserSettingsService } from './user-settings.service';
import { ILocalizationExtension } from '@profis-engineering/pe-ui-common/services/extensions.common';
import { LocalizationService } from './localization.service';
import { UserService } from './user.service';
import { designTypes } from './design.service';

@Injectable({
    providedIn: 'root'
})
export class TourService extends TourServiceInjected {

    constructor(
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private localization: LocalizationService) {
        super();
    }

    public initialize() {
        this.baseService.localizationExtension = {
            getTranslation: this.localization.getString.bind(this.localization)
        } as ILocalizationExtension;
    }
    public getVirtualTours(): IVirtualTour[] {
        return [{
            name: 'MasonryReinforcementWalktrough',
            order: 1,
            buttonKey: 'MasonryRnf.VitualTour.Walktrough',
            openTour: this.walktroughTour.bind(this),
            isAvailable: () => {
                return this.userService.design?.designTypeId == designTypes.main.id;
            },

            alreadySeen: () => this.userSettingsService.settings.masonryRnf.tourSeen.value ?? false,
            markAsSeen: async () => {
                this.userSettingsService.settings.masonryRnf.tourSeen.value = true;
                await this.userSettingsService.save();
            }
        },];
    }

    private get rootElement() {
        return document.documentElement.querySelector('masonry-rnf-main')?.shadowRoot;
    }

    public walktroughTour() {
        const title = 'MasonryRnf.VitualTour.Title';
        const numberOfSteps = 4;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;

        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate(title, 'MasonryRnf.VitualTour.WelcomeText', numberOfSteps, 1),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menuContainer?.querySelector('.tab-menu') as HTMLElement,
                    intro: this.introStepTemplate(title, 'MasonryRnf.VitualTour.MenuText', numberOfSteps, 2),
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.main-content-right-util') as HTMLElement,
                    intro: this.introStepTemplate(title, 'MasonryRnf.VitualTour.ResultSectionText', numberOfSteps, 3),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.design-section-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'MasonryRnf.VitualTour.CreateReportText', numberOfSteps, 4),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
            ]
        });
    }
}
