import { Injectable } from '@angular/core';
import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { DesignExternalMetaData, DocumentAccessMode, DocumentServiceBase, IDefferedData as BaseDefferedData, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import cloneDeep from 'lodash-es/cloneDeep';
import { ProjectDesign } from './design.service';

type IDefferedData = BaseDefferedData<ProjectDesign, IDetailedDisplayDesign>;

// TODO FILIP: fix common
export interface DocumentServiceBaseMissing {
    openDesign(design: IBaseDesign): Promise<ProjectDesign>;
    smallDesignChange(internalDesign: IDesignListItem, projectDesign: ProjectDesign, data: IDefferedData, metaData: DesignExternalMetaData): Promise<void>;
    defferedRequests: IDefferedRequests<unknown, unknown>;
    createUniqueName(oldName: string, usedNames: string[]): string;
}

export interface IDefferedImageData {
    designId: string;
    thumbnailContent: string;
    respond: boolean;
    isTemplate: boolean;
}

interface IDefferedRequests<TProjectDesign, TDetailedDisplayDesign> {
    [designId: string]: {
        defferedData: BaseDefferedData<TProjectDesign, TDetailedDisplayDesign> | IDefferedImageData | null;
        deffered: Deferred<void> | null;
        running: Promise<void>;
    };
}
// -----------------------------

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    protected baseService!: DocumentServiceBase & DocumentServiceBaseMissing;

    public setBaseService(baseService: DocumentServiceBase & DocumentServiceBaseMissing) {
        this.baseService = baseService;

        this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.publish = baseService.publish.bind(baseService);
        this.copyDesign = baseService.copyDesign.bind(baseService);
        this.moveDesign = baseService.moveDesign.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
        this.openDesign = baseService.openDesign.bind(baseService);
    }

    public get projects() {
        return this.baseService.projects;
    }

    public get draftsProject() {
        return this.baseService.draftsProject;
    }

    public findProjectByDesignId!: InstanceType<typeof DocumentServiceBase>['findProjectByDesignId'];
    public findDesignById!: InstanceType<typeof DocumentServiceBase>['findDesignById'];
    public findProjectById!: InstanceType<typeof DocumentServiceBase>['findProjectById'];
    public addDesignCommon!: InstanceType<typeof DocumentServiceBase>['addDesignCommon'];
    public updateDesignWithNewContentCommon!: InstanceType<typeof DocumentServiceBase>['updateDesignWithNewContentCommon'];
    public updateDesignThumbnailImage!: InstanceType<typeof DocumentServiceBase>['updateDesignThumbnailImage'];
    public publish!: InstanceType<typeof DocumentServiceBase>['publish'];
    public copyDesign!: InstanceType<typeof DocumentServiceBase>['copyDesign'];
    public moveDesign!: InstanceType<typeof DocumentServiceBase>['moveDesign'];
    public openDesignExclusive!: InstanceType<typeof DocumentServiceBase>['openDesignExclusive'];
    public getSessionKeyForDesign!: InstanceType<typeof DocumentServiceBase>['getSessionKeyForDesign'];
    public openDesign!: DocumentServiceBaseMissing['openDesign'];

    // TODO FILIP: fix common
    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesign,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.baseService.defferedRequests[designId] == null || (this.baseService.defferedRequests[designId] != null && this.baseService.defferedRequests[designId].running == null)) {
            this.baseService.defferedRequests[designId] = {
                running: this.putSmallDesingChangePromise({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.baseService.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.baseService.defferedRequests[designId].deffered == null) {
                this.baseService.defferedRequests[designId].deffered = new Deferred();
            }

            this.baseService.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await (this.baseService.defferedRequests[designId].deffered as Deferred).promise;
        }
    }

    private async putSmallDesingChangePromise(data: IDefferedData): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        const metaData = cloneDeep(data.metadataOverride);

        await this.baseService.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    public createUniqueName(oldName: string, usedNames: string[]): string {
        return this.baseService.createUniqueName(oldName, usedNames);
    }

    // -----------------------------
}
