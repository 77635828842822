import type schema from './environment.local.json';
import { EnvironmentHelper } from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import { QueryStringHelper } from '@profis-engineering/pe-ui-common/helpers/query-string-helper';

export type Environment = typeof schema & {
    baseUrl: string;
    featureFlags: unknown[];
    debugGlModel: boolean;
    /** contains files hashes like translations */
    manifest?: Record<string, string>;
};

export const environment: Environment = getEnvironmentSettings();

const qsHelper = new QueryStringHelper(window.location.search);

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

if (environment.featureFlagsQueryEnabled) {
    if (qsHelper.queryParamExists('masonryrnfdemo')) {
        environment.enabled = qsHelper.getBoolean('masonryrnfdemo');
    }

    if (qsHelper.getBoolean('masonryrnfdemo')) {
        environment.exportHtmlReport = true;
    }
}

// set debug
environment.debugGlModel = qsHelper.getBoolean('debug');

// set webassembly
if (environment.webassembly != true && qsHelper.getBoolean('wasm')) {
    environment.webassembly = true;
}

declare global {
    interface Window {
        environmentMasonryRnf: Environment;
    }
}

function getEnvironmentSettings(): Environment {
    const env: Environment = window.environmentMasonryRnf;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.designServiceUrl = helper.formatServiceVersion(env.designServiceUrl, serviceVersion);
        env.calculationServiceUrl = helper.formatServiceVersion(env.calculationServiceUrl, serviceVersion);
        env.reportServiceUrl = helper.formatServiceVersion(env.reportServiceUrl, serviceVersion);
        env.htmlReportServiceUrl = helper.formatServiceVersion(env.htmlReportServiceUrl, serviceVersion);
    }

    return env ?? {};
}
