<div class="box-section box-section-white">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static"
            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage">
            <span class="bar-title" [innerHtml]="'MasonryRnf.OutputCapacityOfRetrofittedWall' | l10n: localizationService.selectedLanguage"></span>
        </div>

        <pe-collapse
            [container]="'results-table'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="results-table">
      <table>
        <tbody>
            <tr>
                <th></th>
                <th>NTC</th>
                <th>HILTI</th>
            </tr>
            <tr>
              <td><span class="equation" [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.MaximumAxialLoad' | l10n: localizationService.selectedLanguage">N<sub>rd,retro</sub></span></td>
              <td [class.not-verified]="!maximumAxialLoadNtcVerified" [class.verified]="maximumAxialLoadNtcVerified">{{ maximumAxialLoadNtcDisplay }}</td>
              <td [class.not-verified]="!maximumAxialLoadVerified" [class.verified]="maximumAxialLoadVerified">{{ maximumAxialLoadDisplay }}</td>
            </tr>
            <tr>
              <td><span class="equation" [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.MaximumInPlaneBendingMoment' | l10n: localizationService.selectedLanguage">M<sub>rd,in,retro</sub></span></td>
              <td [class.not-verified]="!maximumInPlaneBendingMomentNtcVerified" [class.verified]="maximumInPlaneBendingMomentNtcVerified">{{ maximumInPlaneBendingMomentNtcDisplay }}</td>
              <td [class.not-verified]="!maximumInPlaneBendingMomentVerified" [class.verified]="maximumInPlaneBendingMomentVerified">{{ maximumInPlaneBendingMomentDisplay }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.MaximumOutOfPlaneBendingMoment' | l10n: localizationService.selectedLanguage">M<sub>rd,out,retro</sub></span></td>
                <td [class.not-verified]="!maximumOutOfPlaneBendingMomentNtcVerified" [class.verified]="maximumOutOfPlaneBendingMomentNtcVerified">{{ maximumOutOfPlaneBendingMomentNtcDisplay }}</td>
                <td [class.not-verified]="!maximumOutOfPlaneBendingMomentVerified" [class.verified]="maximumOutOfPlaneBendingMomentVerified">{{ maximumOutOfPlaneBendingMomentDisplay }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.MaximumShearResistanceDiagonalCracking' | l10n: localizationService.selectedLanguage">V<sub>rd,1,retro</sub></span></td>
                <td [class.not-verified]="!maximumShearResistanceDiagonalCrackingNtcVerified" [class.verified]="maximumShearResistanceDiagonalCrackingNtcVerified">{{ maximumShearResistanceDiagonalCrackingNtcDisplay }}</td>
                <td [class.not-verified]="!maximumShearResistanceDiagonalCrackingVerified" [class.verified]="maximumShearResistanceDiagonalCrackingVerified">{{ maximumShearResistanceDiagonalCrackingDisplay }}</td>
            </tr>
            <tr>
                <td><span [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.MaximumShearResistanceStairStepped' | l10n: localizationService.selectedLanguage">V<sub>rd,2,retro</sub></span></td>
                <td [class.not-verified]="!maximumShearResistanceStairSteppedNtcVerified" [class.verified]="maximumShearResistanceStairSteppedNtcVerified">{{ maximumShearResistanceStairSteppedNtcDisplay }}</td>
                <td [class.not-verified]="!maximumShearResistanceStairSteppedVerified" [class.verified]="maximumShearResistanceStairSteppedVerified">{{ maximumShearResistanceStairSteppedDisplay }}</td>
            </tr>
            <tr>
                <td><span class="equation" [ngbTooltip]="'MasonryRnf.OutputCapacityOfRetrofittedWall.SpecificWeight' | l10n: localizationService.selectedLanguage">γ<sub>m,retro</sub></span></td>
                <td colspan="2">{{ specificWeightDisplay }}</td>
            </tr>
        </tbody>
    </table>
</div>