import { Injectable } from '@angular/core';

export interface SharedEnvironmentData {
    readonly translate: boolean;
    readonly baseUrl: string;
    readonly useDevFeatures: boolean;
    readonly debugModeEnabled: boolean;
    readonly applicationVersion: string;
    readonly peTrackingServiceUrl: string;
}

export function fromService(data: SharedEnvironmentData): SharedEnvironmentData {
    return {
        translate: data.translate,
        baseUrl: data.baseUrl,
        useDevFeatures: data.useDevFeatures,
        debugModeEnabled: data.debugModeEnabled,
        applicationVersion: data.applicationVersion,
        peTrackingServiceUrl: data.peTrackingServiceUrl
    };
}

@Injectable({
    providedIn: 'root'
})
export class SharedEnvironmentService {
    public get data() {
        return this.dataInternal;
    }

    private dataInternal!: SharedEnvironmentData;

    public init(data: SharedEnvironmentData) {
        this.dataInternal = fromService(data);
    }
}
