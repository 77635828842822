import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IDesignSectionExportComponentInput, IDesignSectionExportItem, Loading } from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { environment } from '../../../environments/environment';
import { BrowserService } from '../../services/browser.service';
import { DesignDetails, DesignService, ProjectDesign, PropertyIdValue } from '../../services/design.service';
import { DocumentService } from '../../services/document.service';
import { ImportService } from '../../services/import.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { getSpriteAsIconStyle } from '../../sprites';
import { TrackingDetails } from '../../services/tracking.service';
import { UserService } from '../../services/user.service';

// TODO FILIP: why do we have to copy this component?
@Component({
    selector: 'app-design-section',
    templateUrl: './design-section.component.html',
    styleUrls: ['./design-section.component.scss']
})
export class DesignSectionComponent implements OnInit {
    @Input()
    public openExport = () => this.generateReport();

    @Input()
    public openFile = () => this.selectOpenFile();

    @Input()
    public propertyChange! : (propertyChanges: PropertyIdValue[]) => Promise<void>;

    @Input()
    public designDetails!: DesignDetails;

    @Input()
    public trackingDetails!: TrackingDetails;

    @ViewChild('openDesignInput')
    public openDesigninputElement!: ElementRef<HTMLInputElement>;

    public loading = false;
    public duplicating = false;
    public openDesign = false;

    private designSectionExportInput!: IDesignSectionExportComponentInput;

    constructor(
        public localizationService: LocalizationService,
        private modalService: ModalService,
        private documentService: DocumentService,
        private designService: DesignService,
        private importService: ImportService,
        private browserService: BrowserService,
        private userService: UserService,
    ) { }

    public get isCalculationValid() {
        return this.designService.isCalculationValid(this.designDetails.calculationResult);
    }

    public get disabled() {
        return this.designDetails.isTemplate;
    }

    public ngOnInit(): void {
        this.setDesignSectionExportInput();
    }

    public openDesignSectionExport() {
        this.modalService.openDesignSectionExport(this.designSectionExportInput);
    }

    public async generateReport() {
        this.loading = true;
        await this.openGenerateReportPopup();
    }

    public selectOpenFile() {
        if (this.openDesign) {
            return;
        }

        if (this.openDesigninputElement.nativeElement.value == null || this.openDesigninputElement.nativeElement.value == '') {
            setTimeout(() => this.openDesigninputElement.nativeElement.click());
        }
    }

    public async importFileSelected() {
        if (this.openDesigninputElement.nativeElement.value != null
            && this.openDesigninputElement.nativeElement.value != ''
            && this.openDesigninputElement.nativeElement.files != null) {
            const file = this.openDesigninputElement.nativeElement.files[0];

            // clear input
            this.openDesigninputElement.nativeElement.value = '';

            await this.saveChangesBeforeImport(file);
        }
    }

    public async duplicateDesign(): Promise<void> {
        if (this.duplicating) {
            return;
        }

        this.duplicating = true;

        this.trackingDetails.counters.designDuplicate++;
        this.trackingServiceChange();

        try {
            // just create a design on document service and open it in a new tab
            // it's fine if it gets locked since when you close the design on the other tab it will unlock
            const documentDesign = await this.designService.documentServiceCreateDesign({
                projectDesign: this.designDetails.projectDesign,
                designName: this.designDetails.designName!,
                projectId: this.designDetails.projectId!,
                designTypeId: this.designDetails.designTypeId,
                regionId: this.designDetails.regionId
            });

            const openUrl = `${environment.baseUrl.replace(/\/+$/, '')}${UrlPath.main}${documentDesign.id}`;
            window.open(openUrl, '_blank');
        }
        finally {
            this.duplicating = false;
        }
    }

    private async saveChangesBeforeImport(projectDesign: File | Blob, name?: string) {
        await this.import(projectDesign, name);
    }

    private async import(projectDesign: File | Blob, name?: string): Promise<void> {
        this.openDesign = true;

        this.trackingDetails.counters.designImport++;
        this.trackingServiceChange();

        try {
            const project = this.documentService.findProjectById(this.designDetails.projectId!);

            // TODO FILIP: importService.import accepts a nullable name - wrong definition in common
            await this.importService.import(project, this.userService.design, projectDesign, name!, true);
        }
        finally {
            this.openDesign = false;
        }
    }

    private createFileToDeviceExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            buttonClick: () => {
                this.trackingDetails.counters.designExport++;
                this.trackingServiceChange();

                // no await needed
                this.downloadDesignFile(this.designDetails.projectDesign, this.designDetails.projectName!, this.designDetails.designName!)
                    .catch(error => console.error(error));

                return true;
            },
            buttonDisabled: () => this.disabled,
            buttonLoading: Loading.localFile,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile.Description',
            hasLoading: false
        };
    }

    private trackingServiceChange() {
        // no await needed
        this.designService.trackOnDesignOrTemplateChange({
            designDetails: this.designDetails,
            trackingDetails: this.trackingDetails,

            immediateRequest: false
        })
        .catch(error => console.error(error));
    }

    private async downloadDesignFile(projectDesign: ProjectDesign, projectName: string, designName: string): Promise<void> {
        const projectDesignJson = JSON.stringify(projectDesign, undefined, 2);
        const projectDesignBlob = new Blob([projectDesignJson], {
            type: 'application/json'
        });

        const fileNameFirstPart = projectName;
        const fileNameSecondPart = designName;
        const fileName = `${fileNameFirstPart}_${fileNameSecondPart}.pe`;

        await this.browserService.downloadBlob(projectDesignBlob, fileName, false, false);
    }

    private async openGenerateReportPopup() {
        this.modalService.openExportReport({
            propertyChange: this.propertyChange
        });

        this.loading = false;
    }

    private setDesignSectionExportInput() {
        this.designSectionExportInput = {
            exportItems: [
                // PROFIS file to device
                this.createFileToDeviceExportItem(),
                // TODO TEAM: add other exports
            ]
        };
    }
}
