<pe-modal-header
    id="export-report-dialog"
    [modalInstance]="modalInstance"
    [title]="'Agito.Hilti.Profis3.ExportReport.Title' | l10n: localizationService.selectedLanguage"
    [closeButtonDisabled]="submitted"
    [closeButtonId]="'export-report-close-button'">
</pe-modal-header>

<form class="form" #ngForm="ngForm" (ngSubmit)="save()" [ngClass]="{ 'submitted': 'submitted' }" novalidate>

    <pe-modal-body *ngIf="isLoaded">
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.summary }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.Summary"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.summary = !isSectionCollapsed.summary"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-masonry-rnf-sprite" [ngClass]="getArrowClass(isSectionCollapsed.summary)"></span>
            </button>
        </p>

        <!-- Summary section -->
        <div class="section summary" [hidden]="isSectionCollapsed.summary" id="export-report-summary-section">
            <div class="section-row">
                <div class="section-column summary-right">
                    <div class="section-row summary-right-top">
                            <div class="section-column">
                                <pe-label
                                    class="number-of-pucks control-row"
                                    [id]="'export-report-summary-section-number-of-pucks-label'"
                                    [title]="'MasonryRnf.ExportReport.Summary.NumberOfAnchors' | l10n: localizationService.selectedLanguage"
                                    [text]="numberOfAnchors">
                                </pe-label>
                            </div>
                            <div class="section-column">
                                <pe-label
                                    class="distance-pucks control-row"
                                    [id]="'export-report-summary-section-distance-pucks-label'"
                                    [title]="'MasonryRnf.ExportReport.Summary.TotalLengthOfRebars' | l10n: localizationService.selectedLanguage"
                                    [text]="totalLengthOfRebars">
                                </pe-label>
                            </div>
                            <div class="section-column">
                                <pe-label
                                    class="distance-pucks control-row"
                                    [id]="'export-report-summary-section-distance-pucks-label'"
                                    [title]="'MasonryRnf.ExportReport.Summary.MortarVolume' | l10n: localizationService.selectedLanguage"
                                    [text]="mortarVolume">
                                </pe-label>
                            </div>
                    </div>
                    <div class="section-row summary-right-bottom">
                        <div class="section-column">
                            <pe-label
                                class="max-spacing-pucks control-row"
                                [id]="'export-report-summary-section-max-spacing-pucks-label'"
                                [title]="'MasonryRnf.ExportReport.Summary.NumberOfCartridges' | l10n: localizationService.selectedLanguage"
                                [text]="numberOfCartridges">
                            </pe-label>
                        </div>
                        <div class="section-column">
                            <pe-label
                                class="total-free-space control-row"
                                [id]="'export-report-summary-section-total-free-space-label'"
                                [title]="'MasonryRnf.ExportReport.Summary.SteelMeshArea' | l10n: localizationService.selectedLanguage"
                                [text]="steelMeshArea">
                            </pe-label>
                        </div>
                        <div class="section-column">
                            <pe-label
                                class="distance-pucks control-row"
                                [id]="'export-report-summary-section-distance-pucks-label'"
                                [title]="'MasonryRnf.ExportReport.Summary.PlasterVolume' | l10n: localizationService.selectedLanguage"
                                [text]="plasterVolume">
                            </pe-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Project detail section -->
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.projectDetails }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.ProjectDetails"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.projectDetails = !isSectionCollapsed.projectDetails"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-masonry-rnf-sprite" [ngClass]="getArrowClass(isSectionCollapsed.projectDetails)"></span>
            </button>
        </p>
        <div class="project-details" [hidden]="isSectionCollapsed.projectDetails" id="export-report-details-section">
            <pe-export-report-project-details
                [designName]="exportReportProjectDetailsInputs.designName"
                [fasteningPoint]="exportReportProjectDetailsInputs.fasteningPoint"
                (fasteningPointChange)="exportReportProjectDetailsInputs.fasteningPoint = $any($event).detail"
                [fasteningPointVisible]="exportReportProjectDetailsInputs.fasteningPointVisible"
                [fasteningPointTitle]="exportReportProjectDetailsInputs.fasteningPointTitle"
                [fasteningPointId]="exportReportProjectDetailsInputs.fasteningPointId"
                [notes]="exportReportProjectDetailsInputs.notes"
                (notesChange)="exportReportProjectDetailsInputs.notes = $any($event).detail"
                [includeDetailsInReport]="exportReportProjectDetailsInputs.includeDetailsInReport"
                [isIncludeDetailsInReport]="exportReportProjectDetailsInputs.isIncludeDetailsInReport"
                [reportDisabled]="exportReportProjectDetailsInputs.reportDisabled"
                [submitted]="submitted">
            </pe-export-report-project-details>
        </div>

        <!-- Layout section -->
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.layout }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.Layout"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.layout = !isSectionCollapsed.layout"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-masonry-rnf-sprite" [ngClass]="getArrowClass(isSectionCollapsed.layout)"></span>
            </button>
        </p>
        <div class="company-layout" [hidden]="isSectionCollapsed.layout" id="export-report-layout-section">
            <pe-export-report-company-layout
                [templateDropdown]="exportReportCompanyLayoutInputs.templateDropdown"
                [reportTemplate]="exportReportCompanyLayoutInputs.reportTemplate"
                [reportCompanyName]="exportReportCompanyLayoutInputs.reportCompanyName"
                (reportCompanyNameChange)="exportReportCompanyLayoutInputs.reportCompanyName = $any($event).detail"
                [reportAddress]="exportReportCompanyLayoutInputs.reportAddress"
                (reportAddressChange)="exportReportCompanyLayoutInputs.reportAddress = $any($event).detail"
                [reportContactPerson]="exportReportCompanyLayoutInputs.reportContactPerson"
                (reportContactPersonChange)="exportReportCompanyLayoutInputs.reportContactPerson = $any($event).detail"
                [reportPhoneNumber]="exportReportCompanyLayoutInputs.reportPhoneNumber"
                (reportPhoneNumberChange)="exportReportCompanyLayoutInputs.reportPhoneNumber = $any($event).detail"
                [reportEmail]="exportReportCompanyLayoutInputs.reportEmail"
                (reportEmailChange)="exportReportCompanyLayoutInputs.reportEmail = $any($event).detail"
                (reportEmailValid)="exportReportCompanyLayoutInputs.reportEmailValid = $any($event).detail"
                [reportTypeDropdown]="exportReportCompanyLayoutInputs.reportTypeDropdown"
                [languageDropdown]="exportReportCompanyLayoutInputs.languageDropdown"
                [paperSizeDropdown]="exportReportCompanyLayoutInputs.paperSizeDropdown"
                [firstPageNumber]="exportReportCompanyLayoutInputs.firstPageNumber"
                (firstPageNumberChange)="exportReportCompanyLayoutInputs.firstPageNumber = $any($event).detail"
                [loadCombinationDropdown]="exportReportCompanyLayoutInputs.loadCombinationDropdown"
                [loadCaseDropdown]="exportReportCompanyLayoutInputs.loadCaseDropdown"
                [loadCaseHandrailDropdown]="exportReportCompanyLayoutInputs.loadCaseHandrailDropdown"
                [reportTemplateDisabled]="exportReportCompanyLayoutInputs.reportTemplateDisabled"
                [isLoadCombinationDropdownVisible]="exportReportCompanyLayoutInputs.isLoadCombinationDropdownVisible"
                [displayLoadCaseDropdown]="exportReportCompanyLayoutInputs.displayLoadCaseDropdown"
                [handrailSafetyDesign]="exportReportCompanyLayoutInputs.handrailSafetyDesign"
                [isExternalOnlineRussianUser]="exportReportCompanyLayoutInputs.isExternalOnlineRussianUser"
                [formatedReportType]="formattedReportType"
                [formatedPaperSize]="formattedPaperSize"
                [submitted]="submitted"
                (reportTypeChangeToggle)="onReportTypeChange($any($event).detail)">
            </pe-export-report-company-layout>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'export-report-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="'Agito.Hilti.Profis3.ExportReport.Cancel' | l10n: localizationService.selectedLanguage"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            *ngIf="exportHtmlReportEnabled"
            [id]="'export-report-html-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="exportHtmlReport()"
            [text]="'HTML'"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'export-report-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="!formValid || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.ExportReport.SaveAndExport' | l10n: localizationService.selectedLanguage">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
