import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import { IImportDesignProvider, IProjectDesignEntity } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { DesignService, designTypes, ProjectDesign } from './design.service';
import { DocumentService } from './document.service';

interface PassData extends IProjectDesignEntity {
    projectDesign: ProjectDesign;
    designName: string;
}

interface PossibleProjectDesign {
    designTypeId?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ImportDesignProviderService implements IImportDesignProvider {
    public path = `/design/`;

    constructor(
        private designService: DesignService,
        private documentService: DocumentService
    ) { }

    /** file extensions that we support */
    public getValidFileExtensions(): string[] {
        return ['.pe'];
    }

    /** is our file - return true or false */
    public checkFile(fileContent: string): boolean {
        let projectDesign: PossibleProjectDesign;
        try {
            projectDesign = JSON.parse(fileContent) as PossibleProjectDesign;
        }
        catch (error) {
            return false;
        }

        return projectDesign.designTypeId == designTypes.main.id;
    }

    /** pe-ui just passes the same object to other functions in this file */
    public async createProjectDesignEntity(projectDesign: Blob | File, projectName: string, fileNameProposal: string, designName: string, projectDesignContent?: string | undefined): Promise<IProjectDesignEntity> {
        // JSON parse check is already done in checkFile
        const passData: PassData = {
            projectDesign: JSON.parse(projectDesignContent!) as ProjectDesign,
            designName: fileNameProposal
        };
        return passData;
    }

    /** replace - called when we try to import a design with duplicate name */
    public async updateDesignFromExternalFile(oldDesign: IBaseDesign, passData: IProjectDesignEntity, disableCalcMessages?: boolean, openNewWindow?: boolean): Promise<Design> {
        const projectDesign = (passData as PassData).projectDesign;

        const documentDesign = this.documentService.findDesignById(oldDesign.id);
        await this.documentService.openDesignExclusive<ProjectDesign>(documentDesign);

        if (openNewWindow) {
            // we redirect to a new page so we only need to save the design to document service (no document open/lock or tracking open)

            (await this.designService.documentServiceUpdateDesign({
                designId: documentDesign.id,
                designName: documentDesign.designName,
                designTypeId: projectDesign.designTypeId,
                projectDesign: projectDesign,
                projectId: documentDesign.projectId,
                regionId: projectDesign.regionId,

                immediateRequest: true
            }))!;

            return {
                id: documentDesign.id
            } as Design;
        }
        else {
            // normal import with normal design open (document open/lock and tracking open)

            const { designDetails, trackingDetails } = await this.designService.updateDesignFromProjectDesign({
                designId: documentDesign.id,
                projectId: documentDesign.projectId,
                designName: documentDesign.designName,
                projectDesign,

                immediateRequest: true
            }, {
                // error popup is already shown by pe-ui if this function throws an error
                supressErrorMessage: true
            });

            return this.designService.createPeDesignObject(designDetails, trackingDetails);
        }
    }

    /** keep as separate design - called when we try to import a design with duplicate name */
    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        // call create design with autogenerate name on duplicate
        (projectDesignEntity as PassData).designName = name;
    }

    /** create the design - not called on replace design */
    public async createDesign(importData: IImportData, projectId: string, name: string, secondTry: boolean, disableCalcMessages: boolean, trackingEnabled?: boolean | undefined, openNewWindow?: boolean | undefined): Promise<IImportData> {
        const projectDesign = (importData.projectDesign as PassData).projectDesign;
        const designName = (importData.projectDesign as PassData).designName;

        if (openNewWindow) {
            // we redirect to a new page so we only need to save the design to document service (no document open/lock or tracking open)

            // TODO TEAM: there is now way through the document.service API to create a new design and not open/lock it
            // now a new design is created and immediately opened/locked

            const documentDesign = await this.designService.documentServiceCreateDesign({
                designName: designName,
                designTypeId: projectDesign.designTypeId,
                projectId: projectId,
                regionId: projectDesign.regionId,
                projectDesign
            });

            // return wrapper pe design object
            // pe-ui will only use this design object to open the design in a new tab
            importData.design = {
                id: documentDesign.id
            } as Design;
        }
        else {
            // normal import with normal design open (document open/lock and tracking open)

            // create design
            const { designDetails, trackingDetails } = await this.designService.createDesignFromProjectDesign({
                designName,
                projectId,
                projectDesign
            }, {
                // error popup is already shown by pe-ui if this function throws an error
                supressErrorMessage: true
            });

            // return wrapper pe design object
            importData.design = this.designService.createPeDesignObject(designDetails, trackingDetails);
        }

        return importData;
    }
}
