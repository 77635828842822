import {
    Component, Input, NgZone, OnChanges, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    IAddEditDesignComponent, IDetailedDisplayDesignFromModule
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DisplayDesignType, IDetailedDisplayDesign
} from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { required } from '../../required';
import { AppPropertyId, DataService, Unit } from '../../services/data.service';
import {
    CreateDesignResult,
    DesignDetails, DesignService, designTypes, UpdateDesignResult
} from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { InternalDesign } from '../../services/user.service';

interface IDetailedDisplayDesignFromModuleInternal extends IDetailedDisplayDesignFromModule {
    isCreateTemplate?: boolean;
}

export interface SaveValues {
    designName: string;
    projectId: string;

    unitLength: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;
}

@Component({
    templateUrl: './add-edit-design.component.html',
    styleUrls: ['./add-edit-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignComponent implements IAddEditDesignComponent, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public addEditType!: AddEditType;

    @Input()
    public submitted!: boolean;

    @Input()
    public afterOpenInstructions: number[] = [];

    /** isTemplate - only used for AddEditType.add - for AddEditType.edit use this.designDetails.isTemplate */
    @Input()
    public displayDesignType!: DisplayDesignType;

    /**
     * @deprecated use this.designDetails
     */
    @Input()
    public detailedDisplayDesignFromModule?: IDetailedDisplayDesignFromModuleInternal;

    @Input()
    public save = (saveDesign: ISaveDesign) => {
        // TODO FILIP: if this function is only called from pe-ui why if statement???
        // this save function should only be called from pe-ui
        return NgZone.isInAngularZone()
            ? this.saveInternal(saveDesign)
            : this.ngZone.run(() => {
                return this.saveInternal(saveDesign);
            });
    };

    public isLoaded = false;

    public AddEditType = AddEditType;
    public requiredValidator = Validators.required;

    public collapseRegion!: {
        UnitsAndParams: boolean;
    };

    public projectDesignMethodDropdown!: DropdownProps<number>;
    public lengthDropdown!: DropdownProps<UnitType>;
    public stressDropdown!: DropdownProps<UnitType>;
    public forceDropdown!: DropdownProps<UnitType>;
    public momentDropdown!: DropdownProps<UnitType>;
    public areaDropdown!: DropdownProps<UnitType>;
    public itemsPerAreaDropdown!: DropdownProps<UnitType>;
    public volumeDropdown!: DropdownProps<UnitType>;
    public specificWeightDropdown!: DropdownProps<UnitType>;

    constructor(
        private localizationService: LocalizationService,
        private ngZone: NgZone,
        private dataService: DataService,
        private designService: DesignService,
    ) { }

    public get design() {
        return this.detailedDisplayDesignFromModule?.design as InternalDesign | undefined;
    }

    public get designDetails() {
        return this.design?.designDetails as DesignDetails | undefined;
    }

    public get trackingDetails() {
        return this.design?.trackingDetails;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isLoaded) {
            this.collapseRegion = {
                UnitsAndParams: false
            };

            this.initControls();

            this.isLoaded = true;
        }

        if (changes['selectedRegionId']?.firstChange === false) {
            this.onRegionChange();
        }
    }

    private initControls() {
        this.initUnits();

        this.executeAfterOpenInstructions();
    }

    private onRegionChange() {
        this.setDefaultUnits();
    }

    private initUnits() {
        this.lengthDropdown = this.createUnitDropdown(
            'unitLength',
            'Length',
            this.dataService.units.length,
            this.designDetails?.properties.unitLength
        );

        this.stressDropdown = this.createUnitDropdown(
            'unitStress',
            'Stress',
            this.dataService.units.stress,
            this.designDetails?.properties.unitStress
        );

        this.forceDropdown = this.createUnitDropdown(
            'unitForce',
            'Force',
            this.dataService.units.force,
            this.designDetails?.properties.unitForce
        );

        this.momentDropdown = this.createUnitDropdown(
            'unitMoment',
            'Moment',
            this.dataService.units.moment,
            this.designDetails?.properties.unitMoment
        );

        this.areaDropdown = this.createUnitDropdown(
            'unitArea',
            'Area',
            this.dataService.units.area,
            this.designDetails?.properties.unitArea
        );
        this.itemsPerAreaDropdown = this.createUnitDropdown(
            'unitItemsPerArea',
            'ItemsPerArea',
            this.dataService.units.itemsPerArea,
            this.designDetails?.properties.unitItemsPerArea
        );
        this.volumeDropdown = this.createUnitDropdown(
            'unitVolume',
            'Volume',
            this.dataService.units.volume,
            this.designDetails?.properties.unitVolume
        );

        this.specificWeightDropdown = this.createUnitDropdown(
            'unitSpecificWeight',
            'SpecificWeight',
            this.dataService.units.specificWeight,
            this.designDetails?.properties.unitSpecificWeight
        );
    }

    private createUnitDropdown(property: AppPropertyId, key: string, units: Unit[], selectedUnit?: UnitType): DropdownProps<UnitType> {
        return {
            id: 'add-edit-design-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(`Units.${key}`),
            items: units.map(x => ({
                value: x.id,
                text: x.name
            })),
            selectedValue: selectedUnit ?? this.dataService.getPropertyDetail(this.selectedRegionId, property).defaultValue
        };
    }

    private translateDropdownTitle(key: string): string {
        return this.localizationService.getString(`MasonryRnf.AddEditDesign.${key}`);
    }

    private setDefaultUnits() {
        this.lengthDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitLength').defaultValue;
        this.stressDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitStress').defaultValue;
        this.forceDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitForce').defaultValue;
        this.momentDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitMoment').defaultValue;
        this.areaDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitArea').defaultValue;
        this.itemsPerAreaDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitItemsPerArea').defaultValue;
        this.volumeDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitVolume').defaultValue;
        this.specificWeightDropdown.selectedValue = this.dataService.getPropertyDetail(this.selectedRegionId, 'unitSpecificWeight').defaultValue;
    }

    private executeAfterOpenInstructions() {
        if (this.afterOpenInstructions != null) {
            // TODO FILIP: example
            // if (this.afterOpenInstructions.includes(AfterOpenInstruction.ScrollToRebarCalculationSection)) {
            //     this.collapseRegion.RebarCalculation = false;
            //     this.scrollToSection('section-rebar-calculation');
            // }
        }
    }

    private async saveInternal(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        if (this.addEditType == AddEditType.add) {
            try {
                // TODO TEAM: fix common to correctly pass the is template value
                const isTemplate = this.displayDesignType == DisplayDesignType.template ||
                    this.detailedDisplayDesignFromModule?.displayDesignType == DisplayDesignType.template ||
                    this.detailedDisplayDesignFromModule?.isCreateTemplate === true;

                // setTimeout so we don't block the UI loading indicator
                const { designDetails, trackingDetails } = await new Promise<CreateDesignResult>(resolve => setTimeout(() => resolve(this.designService.createDesignOrDesignTemplate({
                    designName: !isTemplate ? saveDesign.designName : undefined,
                    projectId: !isTemplate ? saveDesign.projectId : undefined,

                    templateName: isTemplate ? saveDesign.designName : undefined,
                    templateProjectId: isTemplate ? saveDesign.templateFolderId : undefined,

                    designTypeId: designTypes.main.id,
                    regionId: this.selectedRegionId,

                    unitLength: this.lengthDropdown.selectedValue!,
                    unitStress: this.stressDropdown.selectedValue!,
                    unitForce: this.forceDropdown.selectedValue!,
                    unitMoment: this.momentDropdown.selectedValue!,
                    unitArea: this.areaDropdown.selectedValue!,
                    unitItemsPerArea: this.itemsPerAreaDropdown.selectedValue!,
                    unitVolume: this.volumeDropdown.selectedValue!,
                    unitSpecificWeight: this.specificWeightDropdown.selectedValue!
                }))));

                const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

                return {
                    designId: designDetails.isTemplate ? designDetails.templateId! : designDetails.designId!,
                    path: `${UrlPath.main}`,
                    design: peDesignObject,
                    detailedDisplayDesign: designDetails as unknown as IDetailedDisplayDesign,
                    success: true
                };
            }
            catch(error) {
                console.error(error);

                return {
                    designId: '',
                    path: '',
                    design: undefined as unknown as Design,
                    success: false
                };
            }
        }
        else if (this.addEditType == AddEditType.edit) {
            // setTimeout so we don't block the UI loading indicator
            const updateDesignResult = (await new Promise<UpdateDesignResult | undefined>(resolve => setTimeout(() => {
                required(this.designDetails);
                required(this.trackingDetails);

                resolve(this.designService.updateDesignOrDesignTemplate({
                    designTypeId: this.designDetails.designTypeId,
                    designId: !this.designDetails.isTemplate ? this.designDetails.designId! : undefined,
                    designName: !this.designDetails.isTemplate ? saveDesign.designName : undefined,
                    projectId: !this.designDetails.isTemplate ? saveDesign.projectId : undefined,

                    templateId: this.designDetails.isTemplate ? this.designDetails.templateId! : undefined,
                    templateName: this.designDetails.isTemplate ? saveDesign.designName : undefined,
                    templateProjectId: this.designDetails.isTemplate ? saveDesign.templateFolderId : undefined,

                    trackingDetails: this.trackingDetails,

                    projectDesign: this.designDetails.projectDesign,
                    properties: [
                        { propertyId: 'unitLength', propertyValue: this.lengthDropdown.selectedValue! },
                        { propertyId: 'unitStress', propertyValue: this.stressDropdown.selectedValue! },
                        { propertyId: 'unitForce', propertyValue: this.forceDropdown.selectedValue! },
                        { propertyId: 'unitMoment', propertyValue: this.momentDropdown.selectedValue! },
                        { propertyId: 'unitArea', propertyValue: this.areaDropdown.selectedValue! },
                        { propertyId: 'unitItemsPerArea', propertyValue: this.itemsPerAreaDropdown.selectedValue! },
                        { propertyId: 'unitVolume', propertyValue: this.volumeDropdown.selectedValue! },
                        { propertyId: 'unitSpecificWeight', propertyValue: this.specificWeightDropdown.selectedValue! },
                    ],
                    immediateRequest: true
                }));
            })))!;

            const designDetails = updateDesignResult.designDetails!;
            const peDesignObject = this.detailedDisplayDesignFromModule?.design as Design;
            this.designService.updatePeDesignObject(peDesignObject, designDetails);

            return {
                designId: designDetails.isTemplate ? designDetails.templateId! : designDetails.designId!,
                path: `${UrlPath.main}`,
                design: peDesignObject,
                detailedDisplayDesign: designDetails as unknown as IDetailedDisplayDesign,
                success: true
            };
        }
        else {
            throw new Error('Unknown AddEditType');
        }
    }
}
