import { Injectable } from '@angular/core';
import { UserSettingsServiceInjected, UserSettingsServiceBase } from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { ApplicationSettingsCommon, GeneralValue, IGeneralApplicationSettingsBase, UserSettings as UserSettingsCommon } from '@profis-engineering/pe-ui-common/entities/user-settings';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { AppPropertyId, DataService, Unit } from './data.service';
import debounce from 'lodash-es/debounce';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CollapsingControls } from '../collapsing-controls';

export interface GeneralValueRequired<TValue extends number | string | boolean> {
    value: TValue;
}

// TODO FILIP: fix modularization
interface UserSettingsServiceBaseHack extends UserSettingsServiceBase<UserSettings> {
    originalValues: UserSettings;
    copySettingsValues(cloneData: UserSettings, data: UserSettings): void;
}

export interface UserSettings extends UserSettingsCommon<object, object, ApplicationSettingsCommon<IGeneralApplicationSettingsBase, object>, object> {
    masonryRnf: {
        tourSeen: GeneralValueRequired<boolean>;
        quickStart: {
            main: {
                regionId: GeneralValueRequired<number>;
                readonly resolvedRegionId: number | undefined;
                length: GeneralValueRequired<UnitType>;
                stress: GeneralValueRequired<UnitType>;
                force: GeneralValueRequired<UnitType>;
                moment: GeneralValueRequired<UnitType>;
                area: GeneralValueRequired<UnitType>;
                itemsPerArea: GeneralValueRequired<UnitType>;
                volume: GeneralValueRequired<UnitType>;
                specificWeight: GeneralValueRequired<UnitType>;
            };
        };
    };
}

export class UserSettingsMasonryRnf {
    tourSeen = new GeneralValue<boolean>(null);
    quickStart = {
        main: {
            regionId: new GeneralValue<number>(null),
            length: new GeneralValue<UnitType>(null),
            stress: new GeneralValue<UnitType>(null),
            force: new GeneralValue<UnitType>(null),
            moment: new GeneralValue<UnitType>(null),
            area: new GeneralValue<UnitType>(null),
            itemsPerArea: new GeneralValue<UnitType>(null),
            volume: new GeneralValue<UnitType>(null),
            specificWeight: new GeneralValue<UnitType>(null)
        }
    };

    // helper values
    constructor(
        dataService: DataService,
        userSettingsService: UserSettingsService
    ) {
        // TODO FILIP: fix pe-ui so it ignores functions and getters
        Object.defineProperty(this.quickStart.main, 'resolvedRegionId', {
            get: () => this.getResolvedRegionId(this.quickStart.main.regionId.value, dataService, userSettingsService)
        });
    }

    private getResolvedRegionId(
        regionId: number | null | undefined,
        dataService: DataService,
        userSettingsService: UserSettingsService
    ): number | undefined {
        if (regionId == null || regionId == SpecialRegion.None) {
            return undefined;
        }

        if (regionId == SpecialRegion.Default) {
            return dataService.regionsById[userSettingsService.settings.application.general.regionId.value ?? 0]?.id;
        }

        return dataService.regionsById[regionId]?.id;
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService extends UserSettingsServiceInjected<UserSettings> {
    public debounceSave = debounce(() => this.save(), 500);

    constructor(private dataService: DataService) {
        super();
    }

    public init() {
        this.initMain();

        // other settings
        if (this.settings.masonryRnf.tourSeen.value == null) {
            this.settings.masonryRnf.tourSeen.value = false;
        }

        // TODO FILIP: mark as not changed
        const baseServiceHack = this.baseService as UserSettingsServiceBaseHack;

        if (baseServiceHack.originalValues != null) {
            baseServiceHack.originalValues.masonryRnf = baseServiceHack.originalValues.masonryRnf ?? {};
            baseServiceHack.copySettingsValues(baseServiceHack.originalValues.masonryRnf as unknown as UserSettings, this.settings.masonryRnf as unknown as UserSettings);
        }

        this.initCollapsingControls();
    }

    public get globalRegion(): CommonRegion {
        return this.getCommonRegionById(this.settings.application.general.regionId.value ?? 0);
    }

    private initMain() {
        // quick start
        const quickStart = this.settings.masonryRnf.quickStart.main;
        const dataUnits = this.dataService.units;

        if (quickStart.regionId.value == null || !(quickStart.regionId.value in this.dataService.regionsById)) {
            quickStart.regionId.value = SpecialRegion.Default;
        }

        const regionId = quickStart.resolvedRegionId;

        if (regionId != null) {
            this.initUnit(regionId, quickStart.length, dataUnits.lengthById, 'unitLength');
            this.initUnit(regionId, quickStart.stress, dataUnits.stressById, 'unitStress');
            this.initUnit(regionId, quickStart.force, dataUnits.forceById, 'unitForce');
            this.initUnit(regionId, quickStart.moment, dataUnits.momentById, 'unitMoment');
            this.initUnit(regionId, quickStart.area, dataUnits.areaById, 'unitArea');
            this.initUnit(regionId, quickStart.itemsPerArea, dataUnits.itemsPerAreaById, 'unitItemsPerArea');
            this.initUnit(regionId, quickStart.volume, dataUnits.volumeById, 'unitVolume');
            this.initUnit(regionId, quickStart.specificWeight, dataUnits.specificWeightById, 'unitSpecificWeight');
        }
    }

    private initUnit(regionId: number, userSettingsUnit: GeneralValueRequired<UnitType>, dataUnitById: Record<number, Unit>, propertyUnit: AppPropertyId) {
        if (userSettingsUnit.value == null || !(userSettingsUnit.value in dataUnitById)) {
            userSettingsUnit.value = this.dataService.getPropertyDetail(regionId, propertyUnit).defaultValue ?? UnitType.None;
        }
    }

    private initCollapsingControls() {
        if (this.settings.applicationCollapsedState.value == null) {
            this.settings.applicationCollapsedState.value = {};
        }

        // Right menu
        if (this.settings.applicationCollapsedState.value[CollapsingControls.BillOfMaterial] == null) {
            this.settings.applicationCollapsedState.value[CollapsingControls.BillOfMaterial] = true;
        }

        if (this.settings.applicationCollapsedState.value[CollapsingControls.OutputUtilizationsComponent] == null) {
            this.settings.applicationCollapsedState.value[CollapsingControls.OutputUtilizationsComponent] = true;
        }

        // Left menu
        if (this.settings.applicationCollapsedState.value['masonry-rnf-design-tab-mechanical-calculated-values-section'] == null) {
            this.settings.applicationCollapsedState.value['masonry-rnf-design-tab-mechanical-calculated-values-section'] = true;
        }
        if (this.settings.applicationCollapsedState.value['masonry-rnf-design-tab-reinforcement-calculated-values-section'] == null) {
            this.settings.applicationCollapsedState.value['masonry-rnf-design-tab-reinforcement-calculated-values-section'] = true;
        }
    }
}
