import { Injectable } from '@angular/core';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DataService } from './data.service';
import { UserSettingsService } from './user-settings.service';

export interface ApplicationSettings {
    regionId: KnownRegion | SpecialRegion;
    length: UnitType;
    stress: UnitType;
    force: UnitType;
    moment: UnitType;
    area: UnitType;
    itemsPerArea: UnitType;
    volume: UnitType;
    specificWeight: UnitType;
}

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    constructor(
        private userSettingsService: UserSettingsService,
        private dataService: DataService
    ) { }

    public loadSettings(): ApplicationSettings {
        const quickStart = this.userSettingsService.settings.masonryRnf.quickStart.main;
        return {
            regionId: quickStart.regionId.value,
            length: quickStart.length.value,
            stress: quickStart.stress.value,
            force: quickStart.force.value,
            moment: quickStart.moment.value,
            area: quickStart.area.value,
            itemsPerArea: quickStart.itemsPerArea.value,
            volume: quickStart.volume.value,
            specificWeight: quickStart.specificWeight.value
        };
    }

    public handleRegionChange(applicationSettings: ApplicationSettings, regionId: number): void {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat is the same as None
        if (regionId in this.dataService.regionsById) {
            applicationSettings.length = this.dataService.getPropertyDetail(regionId, 'unitLength').defaultValue;
            applicationSettings.stress = this.dataService.getPropertyDetail(regionId, 'unitStress').defaultValue;
            applicationSettings.force = this.dataService.getPropertyDetail(regionId, 'unitForce').defaultValue;
            applicationSettings.moment = this.dataService.getPropertyDetail(regionId, 'unitMoment').defaultValue;
            applicationSettings.area = this.dataService.getPropertyDetail(regionId, 'unitArea').defaultValue;
            applicationSettings.itemsPerArea = this.dataService.getPropertyDetail(regionId, 'unitItemsPerArea').defaultValue;
            applicationSettings.volume = this.dataService.getPropertyDetail(regionId, 'unitVolume').defaultValue;
            applicationSettings.specificWeight = this.dataService.getPropertyDetail(regionId, 'unitSpecificWeight').defaultValue;
        }
    }

    public updateSettings(applicationSettings: ApplicationSettings): void {
        if (applicationSettings == null) {
            return;
        }

        const quickStart = this.userSettingsService.settings.masonryRnf.quickStart.main;

        quickStart.regionId.value = applicationSettings.regionId;
        quickStart.length.value = applicationSettings.length;
        quickStart.stress.value = applicationSettings.stress;
        quickStart.force.value = applicationSettings.force;
        quickStart.moment.value = applicationSettings.moment;
        quickStart.area.value = applicationSettings.area;
        quickStart.itemsPerArea.value = applicationSettings.itemsPerArea;
        quickStart.volume.value = applicationSettings.volume;
        quickStart.specificWeight.value = applicationSettings.specificWeight;
    }
}
