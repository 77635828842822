import { Injectable } from '@angular/core';
import {
    GuidService as GuidServiceBase
} from '@profis-engineering/pe-ui-common/services/guid.common';

@Injectable({
    providedIn: 'root'
})
export class GuidService extends GuidServiceBase {
    // Nothing to do.
}