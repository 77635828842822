import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { getSpecialRegionName, SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { format, formatKeyValue, sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ApplicationSettings, AppSettingsService } from '../../services/app-settings.service';
import { DataService, Unit } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings.component.html',
    styleUrls: ['./app-settings.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsComponent implements IAppSettingsComponent<ApplicationSettings>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings!: ApplicationSettings;

    public regionDropdown!: Pick<DropdownProps<KnownRegion | SpecialRegion>, 'id' | 'title' | 'items'>;
    public lengthDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public stressDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public forceDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public momentDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public areaDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public itemsPerAreaDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public volumeDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public specificWeightDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;

    public isLoaded = false;

    constructor(
        private appSettingsService: AppSettingsService,
        private userSettingsService: UserSettingsService,
        private localizationService: LocalizationService,
        private dataService: DataService,
        private changeDetectionRef: ChangeDetectorRef
    ) { }

    public get regionId(): KnownRegion | SpecialRegion {
        return this.settings.regionId == SpecialRegion.Default
            ? this.selectedRegionId ?? KnownRegion.Unknown
            : this.settings.regionId;
    }

    public ngOnChanges(): void {
        if (!this.isLoaded) {
            this.onInit();

            this.isLoaded = true;
        }

        this.onChange();
    }

    private onInit() {
        this.initSettings();
        this.createControlsRegion();
        this.createControlsUnit();
    }

    private onChange() {
        this.updateQuickStart(false);
    }

    public onRegionDropdownChange(regionId: number) {
        this.settings.regionId = regionId;

        this.updateQuickStart(true);

        this.changeDetectionRef.detectChanges();
    }

    private initSettings() {
        const quickStart = this.userSettingsService.settings.masonryRnf.quickStart.main;

        this.settings = this.settings ?? {};

        this.settings.regionId = this.settings.regionId ?? quickStart.regionId.value;
        this.settings.length = this.settings.length ?? quickStart.length.value;
        this.settings.stress = this.settings.stress ?? quickStart.stress.value;
        this.settings.force = this.settings.force ?? quickStart.force.value;
        this.settings.moment = this.settings.moment ?? quickStart.moment.value;
        this.settings.area = this.settings.area ?? quickStart.area.value;
        this.settings.itemsPerArea = this.settings.itemsPerArea ?? quickStart.itemsPerArea.value;
        this.settings.volume = this.settings.volume ?? quickStart.volume.value;
        this.settings.specificWeight = this.settings.specificWeight ?? quickStart.specificWeight.value;
    }

    private createControlsRegion() {
        let regionItems = this.dataService.regions.map((x): DropdownItem<KnownRegion | SpecialRegion> => ({
            value: x.id,
            text: this.localizationService.getString(x.nameKey)
        }));

        // sort
        regionItems = sortByUnicode(regionItems, 'text');

        // add None and Default
        regionItems = [
            {
                value: SpecialRegion.None,
                text: this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(SpecialRegion.None))
            },
            {
                value: SpecialRegion.Default,
                text: this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(SpecialRegion.Default))
            },
            ...regionItems
        ];

        this.regionDropdown = {
            id: 'shear-application-settings-region',
            title: this.localizationService.getString('MasonryRnf.ApplicationSettings.Region'),
            items: regionItems
        };

    }

    private createControlsUnit() {
        this.lengthDropdown = this.createUnitDropdown(
            'Length',
            this.dataService.units.length
        );

        this.stressDropdown = this.createUnitDropdown(
            'Stress',
            this.dataService.units.stress
        );
        this.forceDropdown = this.createUnitDropdown(
            'Force',
            this.dataService.units.force
        );
        this.momentDropdown = this.createUnitDropdown(
            'Moment',
            this.dataService.units.moment
        );
        this.areaDropdown = this.createUnitDropdown(
            'Area',
            this.dataService.units.area
        );
        this.itemsPerAreaDropdown = this.createUnitDropdown(
            'ItemsPerArea',
            this.dataService.units.itemsPerArea
        );
        this.volumeDropdown = this.createUnitDropdown(
            'Volume',
            this.dataService.units.volume
        );
        this.specificWeightDropdown = this.createUnitDropdown(
            'SpecificWeight',
            this.dataService.units.specificWeight
        );
    }

    private createUnitDropdown(key: string, codeListUnits: Unit[]) {
        const items = codeListUnits.map(x => ({
            value: x.id,
            text: x.name
        }));

        const unitDropdown: DropdownProps<UnitType> = {
            id: 'masonry-rnf-application-settings-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(`Units.${key}`),
            items
        };

        return unitDropdown;
    }

    private translateDropdownTitle(key: string): string {
        return this.localizationService.getString(`MasonryRnf.ApplicationSettings.${key}`);
    }

    public get allowQuickStart() {
        if (this.settings.regionId == SpecialRegion.None) {
            return false;
        }

        // default region might be a region that is not allowed
        if (this.settings.regionId == SpecialRegion.Default && !(this.selectedRegionId in this.dataService.regionsById)) {
            return false;
        }

        return true;
    }

    public get allowStatusOk() {
        return this.allowQuickStart || this.settings.regionId == SpecialRegion.None;
    }

    public get allowStatus() {
        if (this.allowStatusOk) {
            const translationFormat = this.localizationService.getString('MasonryRnf.ApplicationSettings.Region.Ok');

            return format(translationFormat, this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            const spRegionOrGlobal = this.regionId == SpecialRegion.None
                ? this.selectedRegionId
                : this.regionId;

            const region = this.dataService.allRegionsById[spRegionOrGlobal];
            const translationFormat = this.localizationService.getString('MasonryRnf.ApplicationSettings.Region.Error');

            return formatKeyValue(translationFormat, { region: this.localizationService.getString(region.nameKey) });
        }
    }

    private updateQuickStart(regionChanged: boolean) {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat is the same as None
        if (this.regionId in this.dataService.regionsById) {
            if (regionChanged) {
                this.appSettingsService.handleRegionChange(this.settings, this.regionId);
            }
        }

        this.changeDetectionRef.detectChanges();
    }
}
