import { Injectable } from '@angular/core';
import { Menu, Tab, Region } from './static-menu.definitions';
import { NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { getSpriteAsIconStyle } from '../sprites';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Injectable({
    providedIn: 'root'
})
export class StaticMenuService {
    private menu?: Menu;

    public getMenu() {
        if (this.menu == null) {
            this.menu = this.createMenu();
        }

        return this.menu;
    }

    private createMenu(): Menu {
        return {
            Id: 1,
            Favorites: true,
            Tabs: [
                this.menuDesign(),
                this.menuLoads(),
                this.menuReinforcement()
            ],
            Footer: {
                Controls: []
            }
        };
    }

    private menuDesign(): Tab {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabDesign',
            Id: 1,
            Name: 'masonry-rnf-design-tab',
            Image: 'tab-design',
            IconImage: getSpriteAsIconStyle('sprite-tab-design'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.menuGeometrySection(),
                this.menuMechanicalSection(),
                this.menuMechanicalCalculatedValuesSection(),
            ]
        };
    }

    private menuLoads(): Tab {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabLoads',
            Id: 2,
            Name: 'masonry-rnf-loads-tab',
            Image: 'tab-loads',
            IconImage: getSpriteAsIconStyle('sprite-tab-loads'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.menuLoadsSection(),
            ]
        };
    }

    private menuReinforcement(): Tab {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabReinforcement',
            Id: 3,
            Name: 'masonry-rnf-reinforcement-tab',
            Image: 'tab-anchor-layout',
            IconImage: getSpriteAsIconStyle('sprite-tab-anchor-layout'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.menuReinforcementParametersSection(),
            ]
        };
    }

    private menuGeometrySection(): Region {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionGeometry.Title',
            Id: 11,
            Name: 'masonry-geometry-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'wall-thickness',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionGeometry.MasonryWallThickness',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'wallThickness'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'wall-width',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionGeometry.MasonryWallWidth',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'wallWidth'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'wall-height',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionGeometry.MasonryWallHeight',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'wallHeight'
                }
            ]
        };
    }

    private menuMechanicalSection(): Region {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.Title',
            Id: 12,
            Name: 'masonry-mechanical-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'specific-weight',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.SpecificWeight',
                    UnitGroup: UnitGroup.SpecificWeight,
                    UIPropertyId: 'specificWeight'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'compresive-strength',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.CompressiveStrength',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'compressiveStrength',
                    TooltipDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.CompressiveStrength.Tooltip',
                    TooltipTitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.CompressiveStrength.TooltipTitle',
                    TooltipType: TooltipType.Normal
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shear-strength-diagonal-crack',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.ShearStrengthDiagonalCrack',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'shearStrengthDiagonalCrack',
                    TooltipDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.ShearStrengthDiagonalCrack.Tooltip',
                    TooltipTitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.ShearStrengthDiagonalCrack.TooltipTitle',
                    TooltipType: TooltipType.Normal
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shear-strength-stair-stepped-crack',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.ShearStrengthStairSteppedCrack',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'shearStrengthStairSteppedCrack',
                    TooltipDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.shearStrengthStairSteppedCrack.Tooltip',
                    TooltipTitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.shearStrengthStairSteppedCrack.TooltipTitle',
                    TooltipType: TooltipType.Normal
                },
                {
                    CodelistName: 'knowlageLevel',
                    ControlType: 'Dropdown',
                    Name: 'knowledge-level',
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.KnowledgeLevel',
                    UIPropertyId: 'knowledgeLevel',
                    Size: 100
                },
                {
                    ControlType: 'TextBox',
                    Name: 'partial-safty-factor',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.PartialSafetyFactor',
                    UnitGroup: UnitGroup.None,
                    UIPropertyId: 'partialSafetyFactor',
                    TooltipDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.PartialSafetyFactor.Tooltip',
                    TooltipTitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.RegionMechanicalParameters.PartialSafetyFactor.TooltipTitle',
                    TooltipType: TooltipType.Normal
                },
            ]
        };
    }

    private menuMechanicalCalculatedValuesSection(): Region {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.Title',
            Id: 13,
            Name: 'mechanical-calculated-values-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'confidence-factor',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.ConfidenceFactorCalculated',
                    UnitGroup: UnitGroup.None,
                    UIPropertyId: 'confidenceFactorCalculated'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'compresive-strength-calculated',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.CompressiveStrengthCalculated',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'compressiveStrengthCalculated'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shear-strength-diagonal-crack-calculated',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.ShearStrengthDiagonalCrackCalculated',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'shearStrengthDiagonalCrackCalculated'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shear-strength-stair-stepped-crack-calculated',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.ShearStrengthStairSteppedCrackCalculated',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'shearStrengthStairSteppedCrackCalculated'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'total-number-of-anchors',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabDesign.CharacteristicsOfTheRetrofittedWall.TotalNumberOfAnchors',
                    UnitGroup: UnitGroup.None,
                    UIPropertyId: 'totalNumberOfAnchorsCalculated'
                }
            ]
        };
    }

    private menuLoadsSection(): Region {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabLoads.LoadsSection.Title',
            Id: 13,
            Name: 'loads-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'axial-compressive-load',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabLoads.LoadsSection.AxialCompressiveLoad',
                    UnitGroup: UnitGroup.Force,
                    UIPropertyId: 'axialCompressiveLoad'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'in-plane-bending-moment',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabLoads.LoadsSection.InPlaneBendingMoment',
                    UnitGroup: UnitGroup.Moment,
                    UIPropertyId: 'inPlaneBendingMoment'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'out-of-plane-bending-moment',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabLoads.LoadsSection.OutOfPlaneBendingMoment',
                    UnitGroup: UnitGroup.Moment,
                    UIPropertyId: 'outOfPlaneBendingMoment'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shear-load',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabLoads.LoadsSection.ShearLoad',
                    UnitGroup: UnitGroup.Force,
                    UIPropertyId: 'shearLoad'
                },
            ]
        };
    }

    private menuReinforcementParametersSection(): Region {
        return {
            DisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.Title',
            Id: 14,
            Name: 'reinforcement-parameters-section',
            Controls: [
                {
                    CodelistName: 'plasterThickness',
                    ControlType: 'Dropdown',
                    Name: 'plaster-thickness',
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.PlasterThickness',
                    UIPropertyId: 'plasterThickness',
                    Size: 100
                },
                {
                    CodelistName: 'numberOfAnchors',
                    ControlType: 'Dropdown',
                    Name: 'number-of-anchors',
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.NumberOfAnchors',
                    UIPropertyId: 'numberOfAnchors',
                    Size: 100
                },
                {
                    ControlType: 'TextBox',
                    Name: 'plaster-specific-weight',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.PlasterSpecificWeight',
                    UnitGroup: UnitGroup.SpecificWeight,
                    UIPropertyId: 'plasterSpecificWeight'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'plaster-compressive-strength',
                    Size: 100,
                    TitleDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.PlasterCompressiveStrength',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'plasterCompressiveStrength',
                    TooltipDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.PlasterCompressiveStrength.Tooltip',
                    TooltipTitleDisplayKey: 'MasonryRnf.Navigation.TabReinforcement.ReinforcementParameters.PlasterCompressiveStrength.TooltipTitle',
                    TooltipType: TooltipType.Normal
                }
            ]
        };
    }
}
