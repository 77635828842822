import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UnitService } from '../../services/unit.service';
import { KernelResultsCapacityOfRetrofittedWall } from '../../services/design.service';
import { CollapsingControls } from '../../collapsing-controls';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Component({
    selector: 'app-output-capacity-of-retrofitted-wall',
    templateUrl: './output-capacity-of-retrofitted-wall.component.html',
    styleUrls: ['./output-capacity-of-retrofitted-wall.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class OutputCapacityOfRetrofittedWallComponent implements OnChanges {
    @Input()
    public outputCapacityOfRetrofittedWall?: KernelResultsCapacityOfRetrofittedWall;

    public collapsed = false;

    constructor(
        public localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private unitService: UnitService
    ) { }

    public ngOnChanges(): void {
        this.collapsed = this.userSettingsService.isSectionCollapsed(CollapsingControls.OutputCapacityOfRetrofittedWallComponent);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(CollapsingControls.OutputCapacityOfRetrofittedWallComponent, collapsed);
    }

    public get maximumAxialLoadNtcDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumAxialLoadNtc, 1);
    }

    public get maximumAxialLoadNtcVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumAxialLoadNtcVerified;
    }

    public get maximumAxialLoadDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumAxialLoad, 1);
    }

    public get maximumAxialLoadVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumAxialLoadVerified;
    }

    public get maximumInPlaneBendingMomentNtcDisplay() {
        return this.formatMoment(this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMomentNtc, 1);
    }

    public get maximumInPlaneBendingMomentNtcVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMomentNtcVerified;
    }

    public get maximumInPlaneBendingMomentDisplay() {
        return this.formatMoment(this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMoment, 1);
    }

    public get maximumInPlaneBendingMomentVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumInPlaneBendingMomentVerified;
    }

    public get maximumOutOfPlaneBendingMomentNtcDisplay() {
        return this.formatMoment(this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMomentNtc, 1);
    }

    public get maximumOutOfPlaneBendingMomentNtcVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMomentNtcVerified;
    }

    public get maximumOutOfPlaneBendingMomentDisplay() {
        return this.formatMoment(this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMoment, 1);
    }

    public get maximumOutOfPlaneBendingMomentVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumOutOfPlaneBendingMomentVerified;
    }

    public get maximumShearResistanceDiagonalCrackingNtcDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCrackingNtc, 1);
    }

    public get maximumShearResistanceDiagonalCrackingNtcVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCrackingNtcVerified;
    }

    public get maximumShearResistanceDiagonalCrackingDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCracking, 1);
    }

    public get maximumShearResistanceDiagonalCrackingVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumShearResistanceDiagonalCrackingVerified;
    }

    public get maximumShearResistanceStairSteppedNtcDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairSteppedNtc, 1);
    }

    public get maximumShearResistanceStairSteppedNtcVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairSteppedNtcVerified;
    }

    public get maximumShearResistanceStairSteppedDisplay() {
        return this.formatForce(this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairStepped, 1);
    }

    public get maximumShearResistanceStairSteppedVerified() {
        return this.outputCapacityOfRetrofittedWall?.maximumShearResistanceStairSteppedVerified;
    }

    public get specificWeightDisplay() {
        return this.formatSpecificWeight(this.outputCapacityOfRetrofittedWall?.specificWeight);
    }

    private formatForce(internalUnit: number | undefined, precision?: number): string {
        if (internalUnit == null) {
            return 'NaN';
        }

        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Force);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Force);
        return this.unitService.formatUnitValueArgs(value, unitGroup, precision, undefined, undefined, undefined, true);
    }

    private formatMoment(internalUnit: number | undefined, precision?: number): string {
        if (internalUnit == null) {
            return 'NaN';
        }

        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Moment);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.Moment);
        return this.unitService.formatUnitValueArgs(value, unitGroup, precision, undefined, undefined, undefined, true);
    }

    private formatSpecificWeight(internalUnit: number | undefined): string {
        if (internalUnit == null) {
            return 'NaN';
        }
        const value = this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.SpecificWeight);
        const unitGroup = this.unitService.getDefaultUnit(UnitGroup.SpecificWeight);
        return this.unitService.formatUnitValueArgs(value, unitGroup, undefined, undefined, undefined, undefined, true);
    }
}
